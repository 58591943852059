import {Localized, useLocalization} from "@fluent/react";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
	Box,
	Button,
	Collapse,
	Divider,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from "@mui/material";
import {styled} from "@mui/styles";
import React, {useEffect, useImperativeHandle, useState} from "react";

import CourseTypeSelector from "../courses/CourseTypeSelector";
import parseDate from "../../helpers/parseDate";
import useDateFormat from "../../i18n/useDateFormat";
import RoleInCourse from "../../store/courses/RoleInCourse";
import type StaffMemberRole from "../../store/courses/StaffMemberRole";
import type UserCourse from "../../store/models/UserCourse";
import UserRole from "../../store/models/UserRole";
import type {UserCourseSearchCriteria} from "../../store/services/courseService";
import {courseService} from "../../store/services/courseService";
import useSnackbar from "../../store/ui/useSnackbar";
import UserRoleChips from "./UserRoleChips";
import ChipsSelector from "../../utils/ChipsSelector";
import DualTextLabel from "../../utils/DualTextLabel";
import IconButtonWithTooltip from "../../utils/IconButtonWithTooltip";
import type HeadCell from "../../utils/tables/HeadCell";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";
import useSorting from "../../utils/tables/useSorting";
import usePaginationState from "../../utils/tables/usePaginationState";
import ServerPaginationControls from "../../utils/tables/ServerPaginationControls";
import SortingHeader from "../../utils/tables/SortingHeader";
import SortOrder from "../../utils/tables/SortOrder";
import SearchToolbar from "../../utils/SearchToolbar";
import SubmitButton from "../../utils/SubmitButton";

const staffRoles: StaffMemberRole[] = [UserRole.Teacher, UserRole.Tutor];

const coursesHeadCells: HeadCell<UserCourse>[] = [
	{
		id: "name",
		label: "group-user-organisation-courses-table-column-name",
		sortable: true,
	},
	{
		id: "userRoles",
		label: "group-user-organisation-courses-table-column-roles",
		sortable: false,
		width: 300,
		align: "right",
	},
];

const courseColumnNumber = coursesHeadCells.length + 1;

const initialSearchCriteria: UserCourseSearchCriteria = {};

const rolesInCourse: RoleInCourse[] = [
	UserRole.Student,
	UserRole.Teacher,
	UserRole.Tutor,
];

function Courses(
	props: {
		organisationName: string;
		userId: number;
		topPanel?: React.ReactNode;
		onRolesUpdated?: (courseId: number) => void;
	},
	ref: React.ForwardedRef<{reload: () => void}>
) {
	const {organisationName, userId} = props;

	const {l10n} = useLocalization();

	const [criteria, setCriteria] = useState(initialSearchCriteria);

	const [sortField, sortOrder, changeOrder] = useSorting<keyof UserCourse>(
		"name"
	);

	const {
		page: coursesPage,
		pageFetchStatus,
		fetchFirstPage,
		fetchRelatedPage,
		retryFetching,
		reloadPage,
	} = usePaginationState<UserCourse>();

	const courses = coursesPage.content;

	useImperativeHandle(
		ref,
		() => ({
			reload() {
				reloadPage();
			},
		}),
		[reloadPage]
	);

	useEffect(() => {
		fetchFirstPage(() =>
			courseService.searchUserCourses(
				organisationName,
				userId,
				criteria,
				{field: sortField, descending: sortOrder === SortOrder.Desc},
				10
			)
		);
	}, [
		criteria,
		fetchFirstPage,
		organisationName,
		sortField,
		sortOrder,
		userId,
	]);

	const notFetched = pageFetchStatus !== "succeeded";

	return (
		<Box sx={{display: "flex", flexDirection: "column", height: "100%"}}>
			{props.topPanel}

			<Box sx={{display: "flex", gap: (theme) => theme.spacing(0.5)}}>
				<SearchToolbar
					criteria={criteria}
					searchPlaceholder={l10n.getString("user-courses-search-placeholder")}
					onCriteriaChange={setCriteria}
				>
					<Grid container spacing={4} alignItems="flex-end">
						<Grid item xs={12} md={6}>
							<ChipsSelector
								options={rolesInCourse}
								value={criteria.userRole ?? []}
								label={
									<Localized id="user-courses-filter-roles">Roles</Localized>
								}
								onChange={(roles) =>
									setCriteria((prev) =>
										roles.length === rolesInCourse.length || roles.length === 0
											? {...prev, userRole: undefined}
											: {...prev, userRole: roles}
									)
								}
								getOptionLabel={(role) => (
									<Localized id="user-role-chips-role" vars={{role}} />
								)}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<CourseTypeSelector
								value={criteria.type}
								onChange={(val) =>
									setCriteria((prev) => ({...prev, type: val}))
								}
							/>
						</Grid>
					</Grid>
				</SearchToolbar>
				<ServerPaginationControls
					disabledControls={{
						first: notFetched || !coursesPage.request.first,
						next: notFetched || !coursesPage.request.next,
						previous: notFetched || !coursesPage.request.previous,
					}}
					onClick={fetchRelatedPage}
				/>
			</Box>

			<TableContainer
				sx={{marginTop: (theme) => theme.spacing(2), height: "100%"}}
			>
				<Table stickyHeader>
					<SortingHeader
						onOrderChange={changeOrder}
						order={sortOrder}
						orderBy={sortField}
						headCells={coursesHeadCells}
						loading={pageFetchStatus === "pending"}
						rightAnnex={<TableCell />}
					/>
					<TableBody>
						{pageFetchStatus === "failed" && (
							<LoadingErrorState
								description={
									<Localized id="user-courses-loading-error-descr">
										Something has gone wrong, and we cannot load courses
									</Localized>
								}
								columnNumber={courseColumnNumber}
								onReload={retryFetching}
							/>
						)}
						{pageFetchStatus === "succeeded" && courses.length === 0 && (
							<NoSearchResultsState
								columnNumber={courseColumnNumber}
								title={
									<Localized id="user-courses-no-courses">No courses</Localized>
								}
								description={
									criteria === initialSearchCriteria ? (
										<Localized id="user-courses-no-courses-descr">
											There are no courses for the user in the organisation
										</Localized>
									) : (
										<Localized id="user-courses-no-results-descr">
											No courses were found matching your search criteria. Try
											to adjust filters
										</Localized>
									)
								}
							/>
						)}
						{pageFetchStatus !== "failed" &&
							courses.map((c, index) => (
								<CourseRow
									key={c.id}
									course={c}
									userId={userId}
									labelId={`table-checkbox-${index}`}
									onRolesChanged={() => {
										reloadPage();
										props.onRolesUpdated?.(c.id);
									}}
								/>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
}

const UserCourses = React.forwardRef(Courses);

function CourseRow(props: {
	labelId: string;
	course: UserCourse;
	userId: number;
	onRolesChanged: () => void;
}) {
	const {course} = props;

	const [expanded, setExpanded] = useState(false);

	const formatDate = useDateFormat();

	return (
		<>
			<NoBottomBorderTableRow hover tabIndex={-1}>
				<TableCell component="th" id={props.labelId} scope="row">
					<DualTextLabel
						primaryText={course.name}
						secondaryText={`${formatDate(
							parseDate(course.startDate)
						)} - ${formatDate(parseDate(course.endDate))}`}
					/>
				</TableCell>
				<TableCell>
					<UserRoleChips roles={course.userRoles} align="right" />
				</TableCell>
				<TableCell padding="checkbox">
					<IconButtonWithTooltip
						tooltipTitle={
							expanded ? (
								<Localized id="user-courses-course-action-collapse">
									Collapse
								</Localized>
							) : (
								<Localized id="user-courses-course-action-expand">
									Expand
								</Localized>
							)
						}
						onClick={() => setExpanded((prev) => !prev)}
					>
						{expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButtonWithTooltip>
				</TableCell>
			</NoBottomBorderTableRow>
			<TableRow>
				<TableCell colSpan={3} style={{padding: 0}}>
					<Collapse in={expanded} timeout="auto" unmountOnExit>
						<CoursePanel
							course={course}
							userId={props.userId}
							onRolesChanged={props.onRolesChanged}
						/>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}

function CoursePanel(props: {
	course: UserCourse;
	userId: number;
	onRolesChanged: () => void;
}) {
	const [rolesEditorOpen, setRolesEditorOpen] = useState(false);

	const staffRoles: StaffMemberRole[] = [];
	props.course.userRoles.forEach((r) => {
		if (r === UserRole.Teacher || r === UserRole.Tutor) {
			staffRoles.push(r);
		}
	});

	const student = props.course.userRoles.includes(UserRole.Student);

	return (
		<Table>
			<TableBody>
				{!rolesEditorOpen && (
					<NoBottomBorderTableRow hover>
						<TableCell colSpan={2}>
							<Grid container spacing={1} alignItems="center">
								<Grid item xs={12} sm={4} sx={{fontWeight: 500}}>
									<Localized id="user-courses-course-section-staff-membership">
										Staff member status
									</Localized>
								</Grid>
								<Grid item xs={12} sm={8}>
									{staffRoles.length > 0 ? (
										<UserRoleChips roles={staffRoles} />
									) : (
										<Localized id="user-courses-course-section-staff-membership-no-roles">
											No roles
										</Localized>
									)}
								</Grid>
							</Grid>
						</TableCell>
						<TableCell padding="checkbox">
							<IconButtonWithTooltip
								tooltipTitle={
									<Localized id="user-courses-course-section-staff-membership-action-change-roles">
										Change roles
									</Localized>
								}
								onClick={() => {
									setRolesEditorOpen(true);
								}}
							>
								<EditIcon />
							</IconButtonWithTooltip>
						</TableCell>
					</NoBottomBorderTableRow>
				)}
				{rolesEditorOpen && (
					<NoBottomBorderTableRow>
						<TableCell colSpan={3}>
							<StaffMembershipEditor
								initialRoles={staffRoles}
								courseId={props.course.id}
								userId={props.userId}
								onSaved={() => {
									setRolesEditorOpen(false);
									props.onRolesChanged();
								}}
								onCancel={() => setRolesEditorOpen(false)}
							/>
						</TableCell>
					</NoBottomBorderTableRow>
				)}

				<NoBottomBorderTableRow hover>
					<TableCell colSpan={2}>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={4} sx={{fontWeight: 500}}>
								<Localized id="user-courses-course-section-enrolment">
									Student status
								</Localized>
							</Grid>
							<Grid item xs={12} sm={8}>
								{student ? (
									<Localized id="user-courses-course-section-enrolment-enrolled">
										Enrolled
									</Localized>
								) : (
									<Localized id="user-courses-course-section-enrolment-not-enrolled">
										Not enrolled
									</Localized>
								)}
							</Grid>
						</Grid>
					</TableCell>
					<TableCell padding="checkbox" />
				</NoBottomBorderTableRow>
			</TableBody>
		</Table>
	);
}

function StaffMembershipEditor(props: {
	initialRoles: StaffMemberRole[];
	courseId: number;
	userId: number;
	onSaved: () => void;
	onCancel: () => void;
}) {
	const [roles, setRoles] = useState(props.initialRoles);

	const [saving, setSaving] = useState(false);

	const showSnackbar = useSnackbar();
	const {l10n} = useLocalization();

	async function save() {
		setSaving(true);

		try {
			if (roles.length > 0) {
				await courseService.changeRolesOfStaffMember(
					props.courseId,
					props.userId,
					roles
				);
			} else {
				await courseService.removeStaffMember(props.courseId, props.userId);
			}

			props.onSaved();
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
		} finally {
			setSaving(false);
		}
	}

	return (
		<Paper
			sx={(theme) => ({
				display: "flex",
				padding: theme.spacing(2, 0, 1, 1.5),
				marginLeft: theme.spacing(-1.5),
			})}
		>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={4}>
					<Typography variant="subtitle2">
						<Localized id="user-courses-course-section-staff-membership">
							Staff membership
						</Localized>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={8}>
					<ChipsSelector
						options={staffRoles}
						value={roles}
						label={
							<Localized id="user-courses-course-section-staff-membership-editor-roles">
								Roles
							</Localized>
						}
						onChange={setRoles}
						getOptionLabel={(role) => (
							<Localized id="user-role-chips-role" vars={{role}} />
						)}
					/>
				</Grid>
				<Grid item xs={12}>
					<Divider
						sx={(theme) => ({
							marginLeft: theme.spacing(-1.5),
							marginTop: theme.spacing(2),
						})}
					/>
				</Grid>
				<Grid item xs={12} container justifyContent="flex-end" spacing={1}>
					<Grid item>
						<Button color="primary" onClick={props.onCancel}>
							<Localized id="user-courses-course-section-staff-membership-editor-action-cancel">
								Cancel
							</Localized>
						</Button>
					</Grid>
					<Grid item>
						<SubmitButton
							color="primary"
							variant="text"
							inProgress={saving}
							onClick={save}
						>
							<Localized id="user-courses-course-section-staff-membership-editor-action-save">
								Save
							</Localized>
						</SubmitButton>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
}

const NoBottomBorderTableRow = styled(TableRow)({
	"& > td": {
		borderBottom: 0,
	},
	"& > th": {
		borderBottom: 0,
	},
});

export default UserCourses;
