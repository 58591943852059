import {Stack, Typography} from "@mui/material";
import React from "react";

import exerciseTypeDescriptions from "./exerciseTypeDescriptions";
import ExerciseType from "../../store/exercises/ExerciseType";
import FilterChip from "../../utils/FilterChip";

const exerciseTypes = [
	ExerciseType.Open,
	ExerciseType.Prog,
	ExerciseType.Multi,
	ExerciseType.Math,
	ExerciseType.External,
];

function ExerciseTypeChipSelector(props: {
	availableExerciseTypes: ExerciseType[];
	label?: React.ReactNode;
	value?: ExerciseType;
	onChange: (v: ExerciseType) => void;
}) {
	return (
		<Stack spacing={1}>
			{props.label && (
				<Typography variant="caption" color="textSecondary">
					{props.label}
				</Typography>
			)}

			<Stack direction="row" spacing={1} sx={{flexWrap: "wrap"}}>
				{exerciseTypes
					.filter((t) => props.availableExerciseTypes.includes(t))
					.map((t) => {
						const type = exerciseTypeDescriptions[t];
						return (
							<FilterChip
								key={t}
								label={type.displayName}
								icon={type.icon}
								selected={props.value === t}
								onClick={() => props.onChange(t)}
							/>
						);
					})}
			</Stack>
		</Stack>
	);
}

export default ExerciseTypeChipSelector;
