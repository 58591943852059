import type {AxiosInstance} from "axios";

import {axiosInstance as client} from "./axiosInstance";
import type DashboardName from "../widgets/DashboardName";
import type WidgetSettings from "../widgets/WidgetSettings";

function createService(client: AxiosInstance) {
	async function getAvailableWidgets(
		courseId: number,
		dashboardName: DashboardName
	): Promise<{name: string}[]> {
		const {data} = await client.get(
			`/api/courses/${courseId}/dashboards/${dashboardName}/widgets`
		);

		return data;
	}

	async function getWidgets(
		userId: number,
		courseId: number,
		dashboardName: DashboardName
	): Promise<{name: string; settings: WidgetSettings}[]> {
		const {data} = await client.get(
			`/api/users/${userId}/courses/${courseId}/dashboards/${dashboardName}/widgets`
		);

		return data.content;
	}

	async function deleteWidget(
		userId: number,
		courseId: number,
		dashboardName: DashboardName,
		widgetKey: string
	): Promise<void> {
		await client.delete(
			`/api/users/${userId}/courses/${courseId}/dashboards/${dashboardName}/widgets/${widgetKey}`
		);
	}

	async function insertWidget(
		userId: number,
		courseId: number,
		dashboardName: DashboardName,
		widgetName: string,
		place?: {
			before: string;
		}
	): Promise<void> {
		await client.post(
			`/api/users/${userId}/courses/${courseId}/dashboards/${dashboardName}/widgets`,
			{
				name: widgetName,
				before: place?.before,
			}
		);
	}

	async function updateSettings(
		userId: number,
		courseId: number,
		dashboardName: DashboardName,
		widgetKey: string,
		settings: WidgetSettings
	) {
		await client.put(
			`/api/users/${userId}/courses/${courseId}/dashboards/${dashboardName}/widgets/${widgetKey}`,
			{
				name: widgetKey,
				settings,
			}
		);
	}

	return {
		getWidgets: getWidgets,
		getAvailableWidgets: getAvailableWidgets,
		deleteWidget: deleteWidget,
		insertWidget: insertWidget,
		updateSettings: updateSettings,
	};
}

export default createService(client);
