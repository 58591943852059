import {Localized, useLocalization} from "@fluent/react";
import {Button, Stack, TextField, Typography} from "@mui/material";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";

import {getChapterContent} from "../../store/chapters/getChapterContent";
import {useAppDispatch} from "../../store/hooks";
import {addSectionItem} from "../../store/sections/addSectionItem";
import {ModalDialogName, openDialog} from "../../store/ui/modalDialogSlice";
import TextEditor from "../../utils/TextEditor";
import type {TextEditorApi} from "../../utils/TextEditor/TextEditor";

type Params = {
	id: string;
	chapterId: string;
	sectionId: string;
};

const NewSectionEditor = (): JSX.Element => {
	const {id: courseId, chapterId} = useParams<Params>();

	const history = useHistory();
	const dispatch = useAppDispatch();

	const {l10n} = useLocalization();
	const defaultTitle = l10n.getString(
		"learning-material-section-management-add-section-title-default-text",
		null,
		"New section"
	);
	const [title, setTitle] = useState(defaultTitle);
	const description = useRef<TextEditorApi | null>(null);

	const saveResponseHandler = async () => {
		const numCourseId = Number(courseId);
		const numChapterId = Number(chapterId);
		const result = await dispatch(
			addSectionItem({
				courseId: numCourseId,
				chapterId: numChapterId,
				sectionItem: {
					title: title,
					content: description.current?.getContent() ?? "",
				},
			})
		);
		dispatch(
			getChapterContent({
				courseId: numCourseId,
				chapterId: numChapterId,
			})
		);
		const {id} = unwrapResult(result);

		const sectionPath = history.location.pathname.replace("/new", `/${id}`);
		history.push(sectionPath);

		dispatch(
			openDialog({
				dialogName: ModalDialogName.AddExercise,
				dialogParams: {
					chapterId: numChapterId,
					courseId: numCourseId,
					sectionId: id,
				},
			})
		);
	};

	return (
		<Stack spacing={3} sx={{mb: 3}}>
			<TextField
				value={title}
				id="section title"
				label={
					<Localized id="learning-material-section-management-add-section-title-default-text-label">
						Section title
					</Localized>
				}
				onChange={(e) => setTitle(e.target.value)}
			/>

			<Stack spacing={1} sx={{mt: 2}}>
				<Typography>
					<Localized
						id="learning-material-section-management-add-section-label-description"
						elems={{
							hint: (
								<Typography
									component="span"
									variant="inherit"
									color="textSecondary"
								></Typography>
							),
						}}
					>
						<>{"Description (optional)"}</>
					</Localized>
				</Typography>
				<TextEditor ref={description} />
			</Stack>

			<Stack direction="row" spacing={4} sx={{justifyContent: "flex-end"}}>
				<Button color="primary" onClick={() => history.goBack()}>
					<Localized id="learning-material-section-management-add-section-button-cancel">
						Cancel
					</Localized>
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={saveResponseHandler}
				>
					<Localized id="learning-material-section-management-add-section-button-save-add-subsections">
						Save and add subsections
					</Localized>
				</Button>
			</Stack>
		</Stack>
	);
};

export default NewSectionEditor;
