import {Box, Stack, Typography, alpha} from "@mui/material";
import React from "react";

import loginBackground from "../../images/login-background.jpg";
import {useAppSelector} from "../../store/hooks";

const LoginLogo = (props: {logo: string}): JSX.Element => {
	const customThemeEnabled = useAppSelector((state) => state.theme.enabled);

	return (
		<Stack
			sx={(theme) => ({
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
				height: "100%",
				backgroundColor: alpha(theme.palette.primary.main, 0.6),
				backgroundImage: `url(${loginBackground})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundBlendMode: "soft-light",
				color: theme.palette.primary.contrastText,
				px: 2,
			})}
		>
			<Box
				component="img"
				src={props.logo}
				sx={{maxHeight: "160px", maxWidth: "100%"}}
				alt="Logo"
			/>

			{!customThemeEnabled && (
				<Typography variant="subtitle1" align="center" sx={{mt: -6}}>
					SOLUTIONS FOR BLENDED LEARNING
				</Typography>
			)}
		</Stack>
	);
};

export default LoginLogo;
