import {createAsyncThunk} from "@reduxjs/toolkit";

import type DashboardName from "./DashboardName";
import {keyProvider} from "../keyProvider";
import widgetService from "../services/widgetService";

const insertWidget = createAsyncThunk(
	"widgets/insert",
	async (params: {
		dashboardName: DashboardName;
		userId: number;
		courseId: number;
		widgetName: string;
		place?: {before: string};
	}) => {
		const {courseId, dashboardName, widgetName, place, userId} = params;

		await widgetService.insertWidget(
			userId,
			courseId,
			dashboardName,
			widgetName,
			place
		);

		return {
			dashboardName,
			courseKey: keyProvider.course(courseId),
			widgetName,
			place,
		};
	}
);

export default insertWidget;
