import {createSlice} from "@reduxjs/toolkit";

import deleteCourses from "../courses/deleteCourses";
import type DashboardName from "./DashboardName";
import type FetchStatus from "../FetchStatus";
import fetchWidgets from "./fetchWidgets";
import defineFetchStatus from "../inferFetchStatusFromError";
import insertWidget from "./insertWidget";
import {keyProvider} from "../keyProvider";
import removeWidget from "./removeWidget";

type State = {
	[key in DashboardName]: {
		byKey: {
			[courseKey: string]:
				| {
						keys: string[];
						status: FetchStatus;
				  }
				| undefined;
		};
	};
} & {
	editing: boolean;
};

const initialState: State = {
	"follow-up": {byKey: {}},
	my_corner: {byKey: {}},
	editing: false,
};

const courseWidgetKeysSlice = createSlice({
	name: "courseWidgetKeys",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchWidgets.fulfilled, (state, {payload}) => {
			state[payload.dashboardName].byKey[payload.courseKey] = {
				keys: payload.widgetKeys,
				status: "succeeded",
			};
		});
		builder.addCase(fetchWidgets.pending, (state, action) => {
			const courseKey = keyProvider.course(action.meta.arg.courseId);
			const dashboardName = action.meta.arg.dashboardName;

			const s = obtainState(state, dashboardName, courseKey);
			s.keys = [];
			s.status = "pending";
		});
		builder.addCase(fetchWidgets.rejected, (state, action) => {
			const courseKey = keyProvider.course(action.meta.arg.courseId);
			const dashboardName = action.meta.arg.dashboardName;

			const s = obtainState(state, dashboardName, courseKey);
			s.status = defineFetchStatus(action);
		});

		builder.addCase(insertWidget.fulfilled, (state, {payload}) => {
			state.editing = false;

			const {courseKey, dashboardName, widgetName, place} = payload;

			const s = state[dashboardName].byKey[courseKey];
			if (!s) {
				return;
			}

			const filtered = s.keys.filter((k) => k !== widgetName);

			let insertAt = filtered.length;
			if (place?.before) {
				insertAt = filtered.findIndex((k) => k === place.before);
				if (insertAt < 0) {
					insertAt = 0;
				}
			}

			filtered.splice(insertAt, 0, widgetName);

			s.keys = filtered;
		});
		builder.addCase(insertWidget.pending, (state) => {
			state.editing = true;
		});
		builder.addCase(insertWidget.rejected, (state) => {
			state.editing = false;
		});

		builder.addCase(removeWidget.fulfilled, (state, {payload}) => {
			state.editing = false;

			const {courseKey, dashboardName} = payload;

			const s = state[dashboardName].byKey[courseKey];
			if (!s) {
				return;
			}

			s.keys = s.keys.filter((k) => k !== payload.widgetKey);
		});
		builder.addCase(removeWidget.pending, (state) => {
			state.editing = true;
		});
		builder.addCase(removeWidget.rejected, (state) => {
			state.editing = false;
		});

		builder.addCase(deleteCourses.fulfilled, (state, {payload}) => {
			payload.ids.forEach((id) => {
				delete state["follow-up"].byKey[keyProvider.course(id)];
				delete state["my_corner"].byKey[keyProvider.course(id)];
			});
		});
	},
});

function obtainState(state: State, dashboardName: DashboardName, key: string) {
	let s = state[dashboardName].byKey[key];
	if (!s) {
		s = {
			keys: [],
			status: "none",
		};
		state[dashboardName].byKey[key] = s;
	}

	return s;
}

export default courseWidgetKeysSlice.reducer;
