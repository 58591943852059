import {Chip, Stack, Typography} from "@mui/material";
import React from "react";

import LightTooltip from "../../utils/LightTooltip";

const ExercisePreviewTooltip = (props: {
	children: React.ReactElement;
	exercise: {title: string; tags: string[]};
}): JSX.Element => {
	const {exercise} = props;

	return (
		<LightTooltip
			placement="right"
			enterDelay={500}
			enterNextDelay={500}
			title={
				<Stack spacing={1} sx={{px: 1, py: 1.5, width: "50ch"}}>
					<Typography variant="subtitle2">{exercise.title}</Typography>
					{exercise.tags.length > 0 && (
						<Stack direction="row" spacing={0.5} sx={{flexWrap: "wrap"}}>
							{exercise.tags.map((tag) => (
								<Chip key={tag} label={tag} variant="outlined" />
							))}
						</Stack>
					)}
				</Stack>
			}
		>
			{props.children}
		</LightTooltip>
	);
};

export default ExercisePreviewTooltip;
