import {useEffect, useRef} from "react";

import {WidgetDefinitions} from ".";
import type {DataFetchStatuses, DataFetcher} from "./dataFetch";
import {selectNotFetched} from "./dataFetch";
import {useAppSelector} from "../../../store/hooks";

function intersect<T>(arr1: T[], arr2: T[]) {
	return arr1.filter((n) => arr2.includes(n));
}

function useWidgetDataFetch<TDataKey extends string, TWidgetProps>(
	fetcher: DataFetcher<TDataKey>,
	definitons: WidgetDefinitions<TWidgetProps, TDataKey>,
	fetchOnReload: TDataKey[],
	keys: string[]
): DataFetchStatuses<TDataKey> {
	const toFetchOnReload = useRef(fetchOnReload);

	const statuses = useAppSelector(fetcher.selectFetchStatuses);

	useEffect(() => {
		if (keys.length === 0) {
			return;
		}

		const required = keys.flatMap((key) => definitons[key].requiredData ?? []);

		toFetchOnReload.current = intersect(toFetchOnReload.current, required);

		const notFetched = selectNotFetched(statuses);

		const toFetch = intersect(required, notFetched)
			.concat(toFetchOnReload.current)
			.filter((key, index, keys) => keys.indexOf(key) === index);

		if (toFetch.length > 0) {
			fetcher.fetch(toFetch);
			toFetchOnReload.current = [];
		}
	}, [fetcher, statuses, keys, definitons]);

	return statuses;
}

export default useWidgetDataFetch;
