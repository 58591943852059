import {Localized} from "@fluent/react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
	Button,
	Container,
	Grid2 as Grid,
	Link,
	Typography,
} from "@mui/material";
import React from "react";
import {Link as RouterLink} from "react-router-dom";

import InterfaceLanguageSelector from "../../i18n/InterfaceLanguageSelector";
import {useLocaleChanger} from "../../i18n/LocalisationProvider";
import useCurrentLocale from "../../i18n/useCurrentLocale";
import LoginLogo from "./LoginLogo";

function Copyright() {
	return (
		<Typography variant="body2" align="center" color="textSecondary">
			{"Viope Service © "}
			<Link href="https://viope.com/">Viope Education Oy</Link>
			{` 2001-${new Date().getFullYear()}`}
		</Typography>
	);
}

const PageLayout = (props: {
	children: React.ReactChild;
	logo: string;
	backRef?: string;
}): JSX.Element => {
	const locale = useCurrentLocale();
	const changeLocale = useLocaleChanger();

	return (
		<Grid sx={{height: "100vh"}} container>
			<Grid size={{sm: 5, lg: 6}} sx={{display: {xs: "none", sm: "block"}}}>
				<LoginLogo logo={props.logo} />
			</Grid>
			<Grid
				container
				size={{xs: 12, sm: 7, lg: 6}}
				sx={{
					p: {xs: 2, md: 4},
					overflow: "auto",
					height: "100%",
				}}
			>
				<Grid size={12} container sx={{justifyContent: "flex-end"}}>
					{props.backRef && (
						<Grid size="grow">
							<Button
								color="primary"
								component={RouterLink}
								to={props.backRef}
								startIcon={<KeyboardBackspaceIcon />}
							>
								<Localized id="signup-back-btn">Back</Localized>
							</Button>
						</Grid>
					)}
					<Grid size={4}>
						<InterfaceLanguageSelector value={locale} onChange={changeLocale} />
					</Grid>
				</Grid>
				<Grid
					size={12}
					sx={{
						display: "flex",
						alignItems: "center",
						my: {xs: 2, md: 4},
					}}
				>
					<Container maxWidth="xs">{props.children}</Container>
				</Grid>
				<Grid
					size={12}
					sx={{
						display: "flex",
						alignItems: "flex-end",
						justifyContent: "center",
					}}
				>
					<Copyright />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PageLayout;
