import {createAsyncThunk} from "@reduxjs/toolkit";
import {sectionService} from "../services/sectionService";

export const addExercisesToSection = createAsyncThunk(
	"sections/addExercises",
	async (params: {
		courseId: number;
		chapterId: number;
		sectionId: number;
		exerciseIds: number[];
	}) => {
		await sectionService.addExercises(
			params.courseId,
			params.chapterId,
			params.sectionId,
			params.exerciseIds
		);
	}
);
