import {Localized} from "@fluent/react";
import {Stack, Typography} from "@mui/material";
import React from "react";

import Widget from "./Widget";
import WidgetBody from "./WidgetBody";

function NotSupportedWidget(props: {id: string}) {
	return (
		<Widget id={props.id}>
			<WidgetBody>
				<Stack sx={{alignItems: "center", pt: 5, pb: 3, width: 1}}>
					<Typography variant="h6">
						<Localized id="widgets-not-supported-widget-title">
							Not supported
						</Localized>
					</Typography>
					<Typography>
						<Localized id="widgets-not-supported-widget-desc">
							Try to reload the page to get the new version of the application
						</Localized>
					</Typography>
				</Stack>
			</WidgetBody>
		</Widget>
	);
}

const notSupportedWidgetDef = {
	title: (
		<Localized id="widgets-not-supported-widget-title">Not supported</Localized>
	),
	icon: <></>,
	description: <></>,
	component: NotSupportedWidget,
};

export default NotSupportedWidget;
export {notSupportedWidgetDef};
