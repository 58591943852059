import type {Widget} from "./availableCourseWidgetsSlice";
import type DashboardName from "./DashboardName";
import type FetchStatus from "../FetchStatus";
import type {RootState} from "../store";

const empty: Widget[] = [];

function selectAvailableWidgets<T extends DashboardName>(
	state: RootState,
	dashboardName: T,
	courseKey: string
): Widget[] {
	return (
		state.widgets.availableCourseWidgets[dashboardName].byKey[courseKey]
			?.widgets ?? empty
	);
}

function selectAvailableWidgetsFetchStatus(
	state: RootState,
	dashboardName: DashboardName,
	courseKey: string
): FetchStatus {
	return (
		state.widgets.availableCourseWidgets[dashboardName].byKey[courseKey]
			?.status ?? "none"
	);
}

export default selectAvailableWidgets;
export {selectAvailableWidgetsFetchStatus};
