import {Box, Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";

import exerciseTypeDescriptions from "./exerciseTypeDescriptions";
import ExerciseType from "../../store/exercises/ExerciseType";
import LightTooltip from "../../utils/LightTooltip";

const useStyles = makeStyles((theme) =>
	createStyles({
		exerciseTypeIcon: {
			color: theme.palette.action.active,
		},
	})
);

const ExerciseTypeLabel = ({
	type,
	size,
}: {
	type: ExerciseType;
	size?: "default" | "small";
}): JSX.Element => {
	const classes = useStyles();
	const currentType = exerciseTypeDescriptions[type];

	if (size === "small") {
		return (
			<LightTooltip
				title={
					<Typography variant="subtitle2">
						{currentType?.displayName}
					</Typography>
				}
				placement="right"
				enterDelay={500}
				enterNextDelay={500}
			>
				<Box display="flex" className={classes.exerciseTypeIcon}>
					{currentType?.icon}
				</Box>
			</LightTooltip>
		);
	}

	return (
		<Box display="flex" alignItems="center" whiteSpace="nowrap">
			<Box mr={1} display="flex" className={classes.exerciseTypeIcon}>
				{currentType?.icon}
			</Box>
			{currentType?.displayName}
		</Box>
	);
};

export default ExerciseTypeLabel;
