import DoneIcon from "@mui/icons-material/Done";
import React from "react";

import ChoiceChip from "./ChoiceChip";
import type {ChoiceChipProps} from "./ChoiceChip";

function FilterChip(props: ChoiceChipProps) {
	const {icon, ...chip} = props;

	return <ChoiceChip {...chip} icon={props.selected ? <DoneIcon /> : icon} />;
}

export default FilterChip;
