import {Localized} from "@fluent/react";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import {
	Box,
	Checkbox,
	ClickAwayListener,
	Divider,
	Grow,
	IconButton,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Tooltip,
	useTheme,
} from "@mui/material";
import React, {useCallback, useRef, useState} from "react";

import type {Page} from "../../helpers/paginatedSearchHelpers";

type Selection = "all" | "unselectAll" | "page" | "unselectPage";

function BulkSelectionCheckbox(props: {
	allSelected: boolean;
	someSelected: boolean;
	onChange: (selection: Selection) => Promise<void>;
}) {
	const {allSelected, someSelected} = props;

	const [open, setOpen] = useState(false);
	const anchorRef = useRef<HTMLButtonElement>(null);

	const theme = useTheme();

	function close(event: MouseEvent | TouchEvent) {
		if (
			!(
				anchorRef.current &&
				event.target instanceof HTMLElement &&
				anchorRef.current.contains(event.target)
			)
		) {
			setOpen(false);
		}
	}

	async function clickItem(selection: Selection) {
		await props.onChange(selection);

		setOpen(false);
	}

	function clickCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
		if (event.target.checked) {
			props.onChange("all");
			return;
		}
		props.onChange("unselectAll");
	}

	return (
		<>
			<Box display="flex" alignItems="center">
				<Tooltip
					title={
						allSelected ? (
							<Localized id="table-bulk-selection-tooltip-select-none">
								Select none
							</Localized>
						) : (
							<Localized id="table-bulk-selection-tooltip-select-all-filtered">
								Select all filtered
							</Localized>
						)
					}
					enterDelay={500}
					enterNextDelay={500}
				>
					<Checkbox
						checked={allSelected}
						indeterminate={someSelected && !allSelected}
						onChange={clickCheckbox}
					/>
				</Tooltip>
				<Box ml={-1}>
					<Tooltip
						title={
							<Localized id="table-bulk-selection-tooltip-select">
								Select ...
							</Localized>
						}
						enterDelay={500}
						enterNextDelay={500}
					>
						<IconButton
							onClick={() => setOpen((prevOpen) => !prevOpen)}
							size="small"
							ref={anchorRef}
						>
							<ArrowDropDown fontSize="small" />
						</IconButton>
					</Tooltip>
				</Box>
			</Box>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				transition
				disablePortal
				placement="bottom-start"
			>
				{({TransitionProps}) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={close}>
								<MenuList autoFocusItem={open}>
									<MenuItem onClick={() => clickItem("page")}>
										<Localized id="table-bulk-selection-option-all-on-page">
											All on page
										</Localized>
									</MenuItem>
									<MenuItem onClick={() => clickItem("unselectPage")}>
										<Localized id="table-bulk-selection-option-none-on-page">
											None on page
										</Localized>
									</MenuItem>
									<Divider style={{margin: theme.spacing(1, 0)}} />
									<MenuItem onClick={() => clickItem("all")}>
										<Localized id="table-bulk-selection-option-all-filtered">
											All filtered
										</Localized>
									</MenuItem>
									<MenuItem onClick={() => clickItem("unselectAll")}>
										<Localized id="table-bulk-selection-option-none">
											None
										</Localized>
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
}

function useBulkSelection<T, K>(
	page: Page<T, K>,
	idSelector: (x: T) => K,
	selectable?: (k: K) => boolean
) {
	const elems = page.content;

	const [selected, setSelected] = useState<K[]>([]);
	const [allSelected, setAllSelected] = useState(false);

	const select = (id: K) => {
		if (selectable && !selectable(id)) {
			return;
		}

		const selectedIndex = selected.indexOf(id);
		let newSelected: K[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else {
			setAllSelected(false);
			if (selectedIndex === 0) {
				newSelected = newSelected.concat(selected.slice(1));
			} else if (selectedIndex === selected.length - 1) {
				newSelected = newSelected.concat(selected.slice(0, -1));
			} else if (selectedIndex > 0) {
				newSelected = newSelected.concat(
					selected.slice(0, selectedIndex),
					selected.slice(selectedIndex + 1)
				);
			}
		}

		setSelected(newSelected);
	};

	function filterSelectable(ids: K[]) {
		return selectable ? ids.filter(selectable) : ids;
	}

	async function changeSelection(selection: Selection) {
		if (selection === "all") {
			const requestAllIds = page.allIds;
			if (requestAllIds) {
				const allIds = await requestAllIds();

				setSelected(filterSelectable(allIds.content));
				setAllSelected(true);
			}

			return;
		}

		if (selection === "unselectAll") {
			setSelected([]);
		} else if (selection === "page") {
			const elemsOnPageIds = filterSelectable(elems.map(idSelector));

			setSelected((prev) =>
				allSelected
					? elemsOnPageIds
					: Array.from(new Set(prev.concat(elemsOnPageIds)))
			);
		} else if (selection === "unselectPage") {
			const elemsOnPageIds = elems.map(idSelector);
			setSelected((prev) => prev.filter((id) => !elemsOnPageIds.includes(id)));
		}

		setAllSelected(false);
	}

	const resetSelection = useCallback(() => {
		setAllSelected(false);
		setSelected([]);
	}, []);

	return {
		bulkSelectionCheckbox: (
			<BulkSelectionCheckbox
				onChange={changeSelection}
				allSelected={allSelected}
				someSelected={selected.length > 0}
			/>
		),
		select,
		selected,
		resetSelection,
	};
}

export default useBulkSelection;
