import {Localized} from "@fluent/react";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import CodeIcon from "@mui/icons-material/Code";
import ExtensionIcon from "@mui/icons-material/Extension";
import React from "react";

import MathExerciseIcon from "../../icons/MathExerciseIcon";
import MultipleChoiceExerciseIcon from "../../icons/MultipleChoiceExerciseIcon";
import OpenExerciseIcon from "../../icons/OpenExerciseIcon";
import ExerciseType from "../../store/exercises/ExerciseType";

const exerciseTypeDescriptions: {
	[key in ExerciseType]: {
		displayName: React.ReactNode;
		icon: React.ReactElement;
		text: React.ReactNode;
	};
} = {
	[ExerciseType.Open]: {
		displayName: (
			<Localized id="exercise-type-open-display-name">Open exercise</Localized>
		),
		icon: <OpenExerciseIcon />,
		text: (
			<Localized id="exercise-type-open-description">
				Exercise with an open answer form
			</Localized>
		),
	},
	[ExerciseType.Prog]: {
		displayName: (
			<Localized id="exercise-type-prog-display-name">
				Programming exercise
			</Localized>
		),
		icon: <CodeIcon />,
		text: (
			<Localized id="exercise-type-prog-description">
				Exercise for creating and verifying code
			</Localized>
		),
	},
	[ExerciseType.Multi]: {
		displayName: (
			<Localized id="exercise-type-multi-display-name">
				Multiple choice exercise
			</Localized>
		),
		icon: <MultipleChoiceExerciseIcon />,
		text: (
			<Localized id="exercise-type-multi-description">
				Exercise with a choice of several answers
			</Localized>
		),
	},
	[ExerciseType.Math]: {
		displayName: (
			<Localized id="exercise-type-math-display-name">Math exercise</Localized>
		),
		icon: <MathExerciseIcon />,
		text: (
			<Localized id="exercise-type-math-description">Math exercise</Localized>
		),
	},
	[ExerciseType.External]: {
		displayName: (
			<Localized id="exercise-type-external-display-name">
				External exercise
			</Localized>
		),
		icon: <ExtensionIcon />,
		text: (
			<Localized id="exercise-type-external-description">
				Exercise with interactive content built with H5P
			</Localized>
		),
	},
	[ExerciseType.Theory]: {
		displayName: (
			<Localized id="exercise-type-theory-display-name">Text block</Localized>
		),
		icon: <ArtTrackIcon />,
		text: (
			<Localized id="exercise-type-theory-description">
				Block with text, images, videos
			</Localized>
		),
	},
};

export default exerciseTypeDescriptions;
