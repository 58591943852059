import {Box, Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React from "react";

import FilterChip from "./FilterChip";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: "flex",
			position: "relative",
		},
		chips: {
			marginTop: theme.spacing(2),
			gap: theme.spacing(0.5),
			display: "flex",
			flexWrap: "wrap",
		},
		label: {
			transform: "translate(0, 1.5px) scale(0.75)",
			transformOrigin: "top left",
			top: 0,
			left: 0,
			position: "absolute",
			color: "#66757A",
			fontSize: "1rem",
			lineHeight: 1,
		},
	})
);

function ChipsSelector<T>(props: {
	options: T[];
	value: T[];
	label?: React.ReactNode;
	onChange: (value: T[]) => void;
	getOptionLabel: (opt: T) => React.ReactNode;
}) {
	const {value, onChange} = props;

	const classes = useStyles();

	return (
		<Box className={classes.root}>
			{props.label && (
				<Typography className={classes.label}>{props.label}</Typography>
			)}
			<Box className={classes.chips}>
				{props.options.map((opt, i) => {
					const selected = value.includes(opt);
					return (
						<FilterChip
							key={i}
							label={props.getOptionLabel(opt)}
							selected={selected}
							clickable
							onClick={() => {
								onChange(
									selected ? value.filter((r) => r !== opt) : [...value, opt]
								);
							}}
						/>
					);
				})}
			</Box>
		</Box>
	);
}

export default ChipsSelector;
