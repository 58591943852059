import {createAsyncThunk} from "@reduxjs/toolkit";

import type DashboardName from "./DashboardName";
import {keyProvider} from "../keyProvider";
import widgetService from "../services/widgetService";

const fetchAvailableWidgets = createAsyncThunk(
	"availableCourseWidgets/fetch",
	async (params: {courseId: number; dashboardName: DashboardName}) => {
		const widgets = await widgetService.getAvailableWidgets(
			params.courseId,
			params.dashboardName
		);

		return {
			courseKey: keyProvider.course(params.courseId),
			dashboardName: params.dashboardName,
			widgets,
		};
	}
);

export default fetchAvailableWidgets;
