import {createSlice} from "@reduxjs/toolkit";

import type DashboardName from "./DashboardName";
import fetchAvailableWidgets from "./fetchAvailableWidgets";
import type FetchStatus from "../FetchStatus";
import inferFetchStatusFromError from "../inferFetchStatusFromError";
import {keyProvider} from "../keyProvider";

type Widget = {
	name: string;
};

type State = {
	[key in DashboardName]: {
		byKey: {
			[courseKey: string]:
				| {
						widgets: Widget[];
						status: FetchStatus;
				  }
				| undefined;
		};
	};
};

const initialState: State = {"follow-up": {byKey: {}}, my_corner: {byKey: {}}};

const availableCourseWidgetsSlice = createSlice({
	name: "availableCourseWidgets",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchAvailableWidgets.pending, (state, action) => {
			const courseKey = keyProvider.course(action.meta.arg.courseId);
			const dashboardName = action.meta.arg.dashboardName;

			const s = obtainState(state, dashboardName, courseKey);
			s.widgets = [];
			s.status = "pending";
		});
		builder.addCase(fetchAvailableWidgets.rejected, (state, action) => {
			const courseKey = keyProvider.course(action.meta.arg.courseId);
			const dashboardName = action.meta.arg.dashboardName;

			const s = obtainState(state, dashboardName, courseKey);
			s.status = inferFetchStatusFromError(action);
		});
		builder.addCase(fetchAvailableWidgets.fulfilled, (state, {payload}) => {
			state[payload.dashboardName].byKey[payload.courseKey] = {
				widgets: payload.widgets,
				status: "succeeded",
			};
		});
	},
});

function obtainState(state: State, dashboardName: DashboardName, key: string) {
	let s = state[dashboardName].byKey[key];
	if (!s) {
		s = {
			widgets: [],
			status: "none",
		};
		state[dashboardName].byKey[key] = s;
	}

	return s;
}

export default availableCourseWidgetsSlice.reducer;
export type {Widget};
