import {Localized} from "@fluent/react";
import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	DialogContent,
	DialogTitle,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Typography,
} from "@mui/material";
import React from "react";

const AddWidgetDialog = (props: {
	availableWidgets: {
		key: string;
		icon: React.ReactNode;
		title: React.ReactNode;
		description: React.ReactNode;
	}[];
	onSelect: (widgetKey: string) => void;
	onClose: () => void;
}): JSX.Element => {
	return (
		<>
			<DialogTitle>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h5" component="span">
						<Localized id="widget-add-widget-dialog-title">
							Add widget
						</Localized>
					</Typography>
					<IconButton onClick={props.onClose}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent>
				<List>
					{props.availableWidgets.map((w) => (
						<ListItem disablePadding key={w.key}>
							<ListItemButton onClick={() => props.onSelect(w.key)}>
								<ListItemAvatar>{w.icon}</ListItemAvatar>
								<ListItemText primary={w.title} secondary={w.description} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</DialogContent>
		</>
	);
};

export default AddWidgetDialog;
