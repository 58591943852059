import {TableCell} from "@mui/material";
import type {TableCellProps} from "@mui/material";
import React from "react";

import type {Column} from "./Head";

function Cell<K extends string = string>(
	props: TableCellProps & {def?: Column<K>}
) {
	const {align, padding, def, sx, ...cell} = props;

	return (
		<TableCell
			align={align ?? def?.align ?? "inherit"}
			padding={padding ?? def?.padding}
			sx={[def && {width: def.width}, ...(Array.isArray(sx) ? sx : [sx])]}
			{...cell}
		/>
	);
}

export default Cell;
