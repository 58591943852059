import type FetchStatus from "../FetchStatus";
import type DashboardName from "./DashboardName";
import type {RootState} from "../store";

const empty: string[] = [];

function selectWidgetKeys<T extends DashboardName>(
	state: RootState,
	dashboardName: T,
	courseKey: string
): string[] {
	return (
		state.widgets.courseWidgetKeys[dashboardName].byKey[courseKey]?.keys ??
		empty
	);
}

function selectWidgetFetchStatus(
	state: RootState,
	dashboardName: DashboardName,
	courseKey: string
): FetchStatus {
	return (
		state.widgets.courseWidgetKeys[dashboardName].byKey[courseKey]?.status ??
		"none"
	);
}

export default selectWidgetKeys;
export {selectWidgetFetchStatus};
