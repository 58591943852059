import {Localized, useLocalization} from "@fluent/react";
import ListAlt from "@mui/icons-material/ListAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import {
	Box,
	Button,
	Dialog,
	Stack,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useEffect, useMemo, useState} from "react";
import {Link as RouterLink, useHistory} from "react-router-dom";

import type {WidgetDefinitions} from "../analytics/widget";
import AddWidgetDialog from "../analytics/widget/AddWidgetDialog";
import {notSupportedWidgetDef} from "../analytics/widget/NotSupportedWidget";
import NoWidgetsEmptyState from "../analytics/widget/NoWidgetsEmptyState";
import selectWidgetKeys, {
	selectWidgetFetchStatus,
} from "../../store/widgets/selectWidgetKeys";
import {createDataFetcher} from "../analytics/widget/dataFetch";
import type {DataFetcher} from "../analytics/widget/dataFetch";
import useWidgetDataFetch from "../analytics/widget/useWidgetDataFetch";
import WidgetNavigation from "../analytics/widget/WidgetNavigation";
import CourseProgressWidget from "./CourseProgressWidget";
import HighScoresWidget from "./HighScoresWidget";
import ChartDataIcon from "../../icons/ChartDataIcon";
import OverallProgressWidget from "./OverallProgressWidget";
import ProgressInChaptersWidget from "./ProgressInChaptersWidget";
import {getChapters} from "../../store/chapters/getChapters";
import selectCourseChaptersFetchStatus from "../../store/chapters/selectCourseChaptersFetchStatus";
import {fetchCourseProgress} from "../../store/courseProgress/fetchCourseProgress";
import selectCourseProgressFetchStatus from "../../store/courseProgress/selectCourseProgressFetchStatus";
import {fetchCourseStats} from "../../store/courseStatistics/fetchCourseStats";
import selectCourseStatsFetchStatus from "../../store/courseStatistics/selectCourseStatsFetchStatus";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import {fetchStudyPeriod} from "../../store/studyPreferences/fetchStudyPeriod";
import selectStudyPeriodStatus from "../../store/studyPreferences/selectStudyPeriodStatus";
import useSnackbar from "../../store/ui/useSnackbar";
import fetchAvailableWidgets from "../../store/widgets/fetchAvailableWidgets";
import fetchWidgets from "../../store/widgets/fetchWidgets";
import insertWidget from "../../store/widgets/insertWidget";
import removeWidget from "../../store/widgets/removeWidget";
import selectAvailableWidgets, {
	selectAvailableWidgetsFetchStatus,
} from "../../store/widgets/selectAvailableWidgets";
import selectWidgetEditing from "../../store/widgets/selectWidgetEditing";
import ExtendableBox from "../../utils/ExtendableBox";
import OpenNavigationButton from "../../utils/OpenNavigationButton";
import ScrollToTopOnMount from "../../utils/ScrollToTopOnMount";

const widgetsNavigationDrawerWidth = 360;

type DataKeys = "progress" | "course_stats" | "study_period" | "chapters";

const widgetDefinitions: WidgetDefinitions<
	{studentId: number; courseId: number},
	DataKeys
> = {
	course_progress: {
		title: (
			<Localized id="my-corner-course-progress-widget-title">
				Course progress
			</Localized>
		),
		icon: <ChartDataIcon color="primary" />,
		description: (
			<Localized id="my-corner-course-progress-widget-descr">
				A chart showing your progress in the course
			</Localized>
		),
		component: CourseProgressWidget,
		requiredData: ["progress", "course_stats", "study_period"],
	},
	high_scores: {
		title: (
			<Localized id="my-corner-high-scores-widget-title">High scores</Localized>
		),
		icon: <ListAlt color="primary" />,
		description: (
			<Localized id="my-corner-high-scores-widget-descr">
				Your ranking among other students of the course
			</Localized>
		),
		component: HighScoresWidget,
		requiredData: ["course_stats"],
	},
	overall_progress: {
		title: (
			<Localized id="my-corner-overall-progress-widget-title">
				Overall progress
			</Localized>
		),
		icon: <ChartDataIcon color="primary" />,
		description: (
			<Localized id="my-corner-overall-progress-widget-descr">
				Your progress in ongoing courses
			</Localized>
		),
		component: OverallProgressWidget,
	},
	progress_in_chapters: {
		icon: <ListAlt color="primary" />,
		title: (
			<Localized id="my-corner-progress-in-chapters-widget-title">
				Progress in chapters
			</Localized>
		),
		description: (
			<Localized id="my-corner-progress-in-chapters-widget-descr">
				Your progress in every chapter of the course
			</Localized>
		),
		component: ProgressInChaptersWidget,
		requiredData: ["progress", "chapters"],
	},
};

const MyCorner = (props: {
	courseId: number;
	studentId: number;
}): JSX.Element => {
	const {courseId, studentId} = props;

	const dispatch = useAppDispatch();

	const courseKey = keyProvider.course(courseId);

	const widgetKeys = useAppSelector((state) =>
		selectWidgetKeys(state, "my_corner", courseKey)
	);
	const widgetFetchStatus = useAppSelector((state) =>
		selectWidgetFetchStatus(state, "my_corner", courseKey)
	);
	const widgetEditRunning = useAppSelector(selectWidgetEditing);

	const availableWidgets = useAppSelector((state) =>
		selectAvailableWidgets(state, "my_corner", courseKey)
	);
	const availableWidgetsFetchStatus = useAppSelector((state) =>
		selectAvailableWidgetsFetchStatus(state, "my_corner", courseKey)
	);

	const [navigationOpened, setNavigationOpened] = useState(false);

	const widgetsShouldBeFetched = widgetFetchStatus === "none";

	useEffect(() => {
		if (widgetsShouldBeFetched) {
			dispatch(
				fetchWidgets({courseId, dashboardName: "my_corner", userId: studentId})
			);
		}
	}, [courseId, dispatch, studentId, widgetsShouldBeFetched]);

	const emptyDashboard =
		widgetFetchStatus === "succeeded" && widgetKeys.length === 0;

	const availableWidgetsShouldBeFetched =
		availableWidgetsFetchStatus === "none" &&
		(navigationOpened || emptyDashboard);

	useEffect(() => {
		if (availableWidgetsShouldBeFetched) {
			dispatch(fetchAvailableWidgets({courseId, dashboardName: "my_corner"}));
		}
	}, [availableWidgetsShouldBeFetched, courseId, dispatch]);

	const fetcher = useMemo<DataFetcher<DataKeys>>(() => {
		const courseKey = keyProvider.course(courseId);

		return createDataFetcher(
			dispatch,
			{
				chapters: (state) => selectCourseChaptersFetchStatus(state, courseKey),
				course_stats: (state) => selectCourseStatsFetchStatus(state, courseKey),
				progress: (state) => selectCourseProgressFetchStatus(state, courseKey),
				study_period: (state) => selectStudyPeriodStatus(state, courseKey),
			},
			{
				chapters: () => getChapters(courseId),
				course_stats: () => fetchCourseStats({courseId}),
				progress: () => fetchCourseProgress({courseId, studentId}),
				study_period: () => fetchStudyPeriod({courseId, studentId}),
			}
		);
	}, [courseId, studentId, dispatch]);

	const supportedWidgetKeys = useMemo(() => {
		return widgetKeys.filter((k) => k in widgetDefinitions);
	}, [widgetKeys]);

	const statuses = useWidgetDataFetch(
		fetcher,
		widgetDefinitions,
		["progress"],
		supportedWidgetKeys
	);

	const [addWidgetDialogOpen, setAddWidgetDialogOpen] = useState(false);

	const theme = useTheme();

	const mobileMode = useMediaQuery(theme.breakpoints.down("md"));

	const history = useHistory();

	const showSnackbar = useSnackbar();

	const {l10n} = useLocalization();
	const errorMsg = l10n.getString("error-general");

	function toggleNavigation() {
		setNavigationOpened((prev) => !prev);
	}

	function closeDialog() {
		setAddWidgetDialogOpen(false);
	}

	async function addWidget(widgetName: string) {
		closeDialog();

		try {
			const res = await dispatch(
				insertWidget({
					courseId,
					dashboardName: "my_corner",
					userId: studentId,
					widgetName,
				})
			);

			unwrapResult(res);

			if (!mobileMode) {
				history.push(`#${name}`);
			}
		} catch {
			showSnackbar("error", errorMsg);
		}
	}

	async function deleteWidget(widgetKey: string) {
		try {
			const res = await dispatch(
				removeWidget({
					courseId,
					dashboardName: "my_corner",
					userId: studentId,
					widgetKey,
				})
			);

			unwrapResult(res);

			if (history.location.hash === `#${widgetKey}`) {
				history.push(history.location.pathname + history.location.search);
			}
		} catch {
			showSnackbar("error", errorMsg);
		}
	}

	async function reorderWidgets(widgetName: string, before: string) {
		try {
			const res = await dispatch(
				insertWidget({
					courseId,
					dashboardName: "my_corner",
					userId: studentId,
					widgetName,
					place: {before},
				})
			);

			unwrapResult(res);
		} catch {
			showSnackbar("error", errorMsg);
		}
	}

	function selectWidgetDefinition(key: string) {
		return widgetDefinitions[key] ?? notSupportedWidgetDef;
	}

	const notAddedWidgets = availableWidgets
		.filter((w) => !widgetKeys.includes(w.name) && w.name in widgetDefinitions)
		.map((w) => {
			const d = selectWidgetDefinition(w.name);

			return {
				key: w.name,
				title: d.title,
				icon: d.icon,
				description: d.description,
			};
		});

	return (
		<Box display="flex" maxWidth={theme.breakpoints.values.xl}>
			<ScrollToTopOnMount />

			<WidgetNavigation
				open={navigationOpened}
				mobileMode={mobileMode}
				widgetKeys={widgetKeys}
				header={
					<Localized id="course-studying-linktab-label-my-corner">
						My corner
					</Localized>
				}
				bottomFixedPanel={
					<Box
						sx={{
							display: "flex",
							minHeight: (theme) => theme.spacing(6),
							my: 1,
							mr: 1,
							ml: 1.5,
						}}
					>
						<Button
							color="primary"
							startIcon={<SettingsIcon />}
							component={RouterLink}
							to={`/courses/${courseId}/study-process-preferences`}
						>
							<Localized id="my-corner-navigation-preferences-link">
								Study process preferences
							</Localized>
						</Button>
					</Box>
				}
				operationRunning={widgetEditRunning}
				onReorder={reorderWidgets}
				onDelete={deleteWidget}
				getWidgetTitle={(key) => selectWidgetDefinition(key).title}
				onClose={toggleNavigation}
				onAdd={
					notAddedWidgets.length > 0
						? () => setAddWidgetDialogOpen(true)
						: undefined
				}
			/>

			<ExtendableBox
				leftExtension={
					navigationOpened || mobileMode ? 0 : widgetsNavigationDrawerWidth
				}
				display="flex"
				p={6}
				minWidth={0}
			>
				<OpenNavigationButton
					hidden={navigationOpened && !mobileMode}
					onClick={toggleNavigation}
				/>

				{widgetKeys.length > 0 && (
					<Stack
						spacing={6}
						sx={{
							width: "100%",
							paddingRight: (theme) => theme.spacing(3),
							marginRight: (theme) => theme.spacing(-3),
						}}
					>
						{widgetKeys.map((key) => {
							const w = selectWidgetDefinition(key);
							return (
								<w.component
									key={key}
									id={key}
									title={w.title}
									courseId={courseId}
									studentId={studentId}
									fetch={fetcher.fetch}
									fetchStatuses={statuses}
								/>
							);
						})}
					</Stack>
				)}

				{emptyDashboard && (
					<NoWidgetsEmptyState
						onAdd={
							availableWidgetsFetchStatus === "succeeded"
								? () => setAddWidgetDialogOpen(true)
								: undefined
						}
					/>
				)}
			</ExtendableBox>

			<Dialog open={addWidgetDialogOpen} onClose={closeDialog} maxWidth="sm">
				<AddWidgetDialog
					availableWidgets={notAddedWidgets}
					onSelect={addWidget}
					onClose={closeDialog}
				/>
			</Dialog>
		</Box>
	);
};

export default MyCorner;
