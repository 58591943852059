import {Localized} from "@fluent/react";
import type {ReactLocalization} from "@fluent/react";
import {unwrapResult} from "@reduxjs/toolkit";
import React from "react";

import type {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import removeExercise from "../../store/chapterExercises/removeExercise";
import ExerciseType from "../../store/exercises/ExerciseType";
import {useAppDispatch} from "../../store/hooks";
import type {ShowSnackbar} from "../../store/ui/useSnackbar";

function useRemoveSubsectionAction(
	courseId: number,
	openConfirmationDialog: OpenConfirmationDialog,
	showSnackbar: ShowSnackbar,
	l10n: ReactLocalization
) {
	const dispatch = useAppDispatch();

	async function removeSubsection(
		subsectionKey: string,
		subsectionType: ExerciseType
	) {
		try {
			const res = await dispatch(
				removeExercise({courseId, chapterExerciseKey: subsectionKey})
			);

			unwrapResult(res);
		} catch (error) {
			showSnackbar(
				"error",
				(error as {code: string}).code === "responses_exist" &&
					subsectionType !== ExerciseType.Theory
					? l10n.getString(
							"learning-material-delete-subsection-error-not-allowed"
					  )
					: l10n.getString("error-general")
			);
		}
	}

	function confirmRemove(
		subsectionKey: string,
		subsection: {title: string; type: ExerciseType}
	) {
		openConfirmationDialog({
			title: (
				<Localized id="learning-material-delete-subsection-confirmation-dialog-title">
					Delete subsection?
				</Localized>
			),
			description:
				subsection.type === ExerciseType.Theory ? (
					<Localized
						id="learning-material-delete-subsection-confirmation-dialog-descr-theory"
						vars={{title: subsection.title}}
					>
						{`You are going to remove subsection '${subsection.title}'.` +
							`It will be deleted permanently.`}
					</Localized>
				) : (
					<Localized
						id="learning-material-delete-subsection-confirmation-dialog-descr-non-theory"
						vars={{title: subsection.title}}
					>
						{`You are going to remove exercise '${subsection.title}'.` +
							` The link will be deleted, but the exercise remains in the exercise bank.`}
					</Localized>
				),
			confirmBtnText: (
				<Localized id="learning-material-delete-subsection-confirmation-dialog-action-confirm">
					Delete
				</Localized>
			),
			onConfirm: () => removeSubsection(subsectionKey, subsection.type),
		});
	}

	return confirmRemove;
}

export default useRemoveSubsectionAction;
