import {Localized} from "@fluent/react";
import EditIcon from "@mui/icons-material/Edit";
import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	Typography,
} from "@mui/material";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";

import ExerciseQuestion from "../content/exercises/ExerciseQuestion";
import DividerWithButton from "./DividerWithButton";
import ExternalExerciseEditor from "./ExternalExerciseEditor";
import type {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import useMobileMode from "../../hooks/useMobileMode";
import LearningMaterialExerciseContent from "./LearningMaterialExerciseContent";
import MathExerciseEditor from "./MathExerciseEditor";
import MultiExerciseEditor from "./MultiExerciseEditor";
import OpenExerciseEditor from "./OpenExerciseEditor";
import ProgExerciseEditor from "./ProgExerciseEditor";
import SectionDescriptionEditor from "./SectionDescriptionEditor";
import SectionSettingsEditor from "./SectionSettingsEditor";
import SectionTitleEditor from "./SectionTitleEditor";
import ContentSidebar from "../sidebars/ContentSidebar";
import SidebarContext, {SidebarType} from "../sidebars/SidebarContext";
import {getChapterContent} from "../../store/chapters/getChapterContent";
import selectChapter from "../../store/chapters/selectChapter";
import {SelectionModes} from "../../store/chapters/SelectionMode";
import SubmissionMode from "../../store/chapters/SubmissionMode";
import selectExerciseDraft from "../../store/exercises/selectExerciseDraft";
import {fetchChapterExercise} from "../../store/chapterExercises/fetchChapterExercise";
import {
	DraftType,
	editingEnded,
	editingStarted,
} from "../../store/exercises/exericseDraftSlice";
import ExerciseType from "../../store/exercises/ExerciseType";
import buildProgExercise from "../../store/exercises/prog/buildExercise";
import exampleOutputChanged from "../../store/exercises/prog/exampleOutputChanged";
import {saveExerciseDraft} from "../../store/exercises/saveExerciseDraft";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import {getSectionContent} from "../../store/sections/getSectionContent";
import makeSelectSection from "../../store/sections/makeSelectSection";
import selectSubsectionKeys, {
	selectSectionContentStatus,
} from "../../store/sections/selectSubsectionKeys";
import updateSectionSettings from "../../store/sections/updateSectionSettings";
import type {
	EditableExercise,
	ProgExercise,
} from "../../store/services/dtos/EditableExercise";
import {ModalDialogName, openDialog} from "../../store/ui/modalDialogSlice";
import useSnackbar from "../../store/ui/useSnackbar";
import TheoryExerciseEditor from "./TheoryExerciseEditor";
import ExtendableBox from "../../utils/ExtendableBox";

const sidebarDrawerWidth = 320;

function SectionContentManagement(props: {
	organisationName: string;
	courseId: number;
	chapterId: number;
	sectionId: number;
	viewOnly?: boolean;
	openConfirmDialog: OpenConfirmationDialog;
}) {
	const {chapterId, courseId, sectionId} = props;

	const sidebarCtx = useContext(SidebarContext);
	const mobileMode = useMobileMode("lg");

	return (
		<Stack direction="row">
			<ExtendableBox
				rightExtension={
					sidebarCtx.type !== SidebarType.None ||
					sidebarCtx.fullscreen ||
					mobileMode
						? 0
						: sidebarDrawerWidth
				}
				minWidth={0}
			>
				<SectionContent
					organisationName={props.organisationName}
					courseId={courseId}
					chapterId={chapterId}
					sectionId={sectionId}
					viewOnly={props.viewOnly}
					openConfirmDialog={props.openConfirmDialog}
				/>
			</ExtendableBox>
			<ContentSidebar
				width={sidebarDrawerWidth}
				sectionKey={keyProvider.section(chapterId, sectionId)}
				courseId={courseId}
				mobileMode={mobileMode}
			/>
		</Stack>
	);
}

function Content(props: {
	organisationName: string;
	courseId: number;
	chapterId: number;
	sectionId: number;

	viewOnly?: boolean;

	openConfirmDialog: OpenConfirmationDialog;
}) {
	const {courseId, chapterId, sectionId} = props;

	const draft = useAppSelector(selectExerciseDraft);
	const editing = draft !== null;

	const chapterKey = keyProvider.chapter(chapterId);
	const chapter = useAppSelector((state) => selectChapter(state, chapterKey));

	const sectionKey = keyProvider.section(chapterId, sectionId);
	const selectSection = useMemo(makeSelectSection, []);
	const section = useAppSelector((state) => selectSection(state, sectionKey));

	const contentRequested = useAppSelector(
		(state) => selectSectionContentStatus(state, sectionKey) !== "none"
	);

	const [editorOpen, setEditorOpen] = useState(false);

	const dispatch = useAppDispatch();

	const patchSectionSettings = useCallback(
		(settings: {selectionSize?: number; maxScorePerExercise?: number}) => {
			dispatch(
				updateSectionSettings({courseId, chapterId, sectionId, settings})
			);
		},
		[courseId, chapterId, sectionId, dispatch]
	);

	useEffect(() => {
		if (draft?.sectionId !== sectionId) {
			setEditorOpen(false);
		}
	}, [draft?.sectionId, sectionId]);

	useEffect(() => {
		if (draft?.newlyAdded !== editorOpen) {
			setEditorOpen(draft?.newlyAdded ?? false);
		}
	}, [editorOpen, draft?.newlyAdded]);

	const sectionLoaded = Boolean(section);

	useEffect(() => {
		if (!contentRequested && sectionLoaded) {
			dispatch(getSectionContent({courseId, sectionKey}));
		}
	}, [contentRequested, courseId, dispatch, sectionLoaded, sectionKey]);

	if (!chapter || !section) {
		return <></>;
	}

	const chapterPublishedOrScheduled = Boolean(chapter.startDate);
	const editingAllowed = section.originId === courseId && !props.viewOnly;
	const randomSelectionEnabled = section.selectionSize > 0;

	const openAddExerciseDialog = () => {
		dispatch(
			openDialog({
				dialogName: ModalDialogName.AddExercise,
				dialogParams: {
					courseId,
					chapterId,
					sectionId,
				},
			})
		);

		setEditorOpen(true);
	};

	const startEditingSection = () => {
		dispatch(
			editingStarted({
				id: sectionId,
				type: DraftType.Section,
				title: section.title,
				content: section.content,
			})
		);
	};

	const saveExercise = async (exercise: EditableExercise) => {
		const result = await dispatch(
			saveExerciseDraft({
				courseId,
				chapterId,
				sectionId,
				exercise,
			})
		);

		return unwrapResult(result).id;
	};

	const closeNewExerciseEditor = async (updated: boolean) => {
		setEditorOpen(false);

		if (!updated) {
			return;
		}

		await dispatch(getChapterContent({courseId, chapterId}));
		dispatch(getSectionContent({courseId, sectionKey}));
	};

	return (
		<Stack spacing={6} sx={{pb: editing ? 10 : 3}}>
			<Stack spacing={3}>
				<SectionTitle
					courseId={courseId}
					chapterId={chapterId}
					sectionId={sectionId}
					sectionNumber={section.number}
					sectionTitle={section.title}
					sectionDescription={section.content}
					viewOnly={!editingAllowed}
					onEdit={startEditingSection}
				/>

				<SectionDescription
					courseId={courseId}
					chapterId={chapterId}
					sectionId={sectionId}
					sectionTitle={section.title}
					sectionDescription={section.content}
					viewOnly={!editingAllowed}
					onEdit={startEditingSection}
				/>

				{chapter.selectionMode === SelectionModes.Random && (
					<SectionSettingsEditor
						key={sectionKey}
						settings={settings(section)}
						disabled={chapterPublishedOrScheduled}
						onChange={patchSectionSettings}
					/>
				)}
			</Stack>

			<SectionExercises
				organisationName={props.organisationName}
				courseId={courseId}
				sectionId={sectionId}
				sectionKey={sectionKey}
				sectionNumber={section.number}
				viewOnly={props.viewOnly}
				individualMaxScore={!randomSelectionEnabled}
				individualSubmission={
					chapter.submissionMode === SubmissionMode.Individual
				}
				deleteionDisabled={chapterPublishedOrScheduled || props.viewOnly}
				settingsEditingDisabled={chapterPublishedOrScheduled || props.viewOnly}
				openConfirmDialog={props.openConfirmDialog}
			/>

			{!props.viewOnly && (
				<DividerWithButton
					buttonHidden={editing}
					onClick={openAddExerciseDialog}
				>
					<Localized id="learning-material-section-management-button-add-subsection">
						+ Add Subsection
					</Localized>
				</DividerWithButton>
			)}

			{editorOpen && (
				<Box sx={{mb: 6}}>
					<NewExerciseEditor
						organisationName={props.organisationName}
						courseId={courseId}
						chapterId={chapterId}
						sectionId={sectionId}
						exerciseType={draft?.exerciseType}
						onSave={saveExercise}
						onClose={closeNewExerciseEditor}
					/>
				</Box>
			)}
		</Stack>
	);
}

const SectionContent = React.memo(Content);

function SectionTitle(props: {
	sectionTitle: string;
	sectionDescription: string;
	sectionNumber: number;

	courseId: number;
	chapterId: number;
	sectionId: number;

	viewOnly?: boolean;

	onEdit: () => void;
}) {
	const {sectionTitle, sectionDescription, sectionId} = props;

	const [sectionTitleEdit, setSectionTitleEdit] = useState(false);

	function openTitleEditor() {
		props.onEdit();
		setSectionTitleEdit(true);
	}

	return (
		<>
			{sectionTitleEdit ? (
				<SectionTitleEditor
					setEdit={setSectionTitleEdit}
					exercise={{
						title: sectionTitle,
						content: sectionDescription,
					}}
					courseId={props.courseId}
					chapterId={props.chapterId}
					sectionId={sectionId}
				/>
			) : (
				<Stack direction="row" spacing={1} sx={{alignItems: "center"}}>
					<Typography variant="h4" sx={{display: "flex", gap: 1}}>
						<Typography
							component="span"
							variant="inherit"
							color="textSecondary"
						>
							{props.sectionNumber}
						</Typography>

						{sectionTitle}
					</Typography>

					{!props.viewOnly && (
						<IconButton onClick={openTitleEditor}>
							<EditIcon />
						</IconButton>
					)}
				</Stack>
			)}
		</>
	);
}

function SectionDescription(props: {
	sectionTitle: string;
	sectionDescription: string;

	courseId: number;
	chapterId: number;
	sectionId: number;

	viewOnly?: boolean;

	onEdit: () => void;
}) {
	const {courseId, chapterId, sectionId, sectionDescription} = props;

	const [sectionDesriptionEdit, setSectionDescriptionEdit] = useState(false);

	const dispatch = useAppDispatch();

	function closeDescriptionEditor(updated: boolean) {
		setSectionDescriptionEdit(false);

		if (!updated) {
			return;
		}

		dispatch(
			fetchChapterExercise({
				courseId,
				chapterId,
				exerciseId: sectionId,
				sectionId: sectionId,
			})
		);
	}

	function openEditor() {
		props.onEdit();

		setSectionDescriptionEdit(true);
	}

	function DescriptionTitle() {
		if (sectionDescription !== "" || sectionDesriptionEdit) {
			return (
				<Stack direction="row" spacing={1} sx={{alignItems: "center"}}>
					<Typography variant="subtitle2">
						<Localized
							id="learning-material-section-description-label"
							elems={{
								hint: (
									<Typography
										component="span"
										variant="inherit"
										color="textSecondary"
									></Typography>
								),
							}}
						>
							<>{"Description <hint>(optional)</hint>"}</>
						</Localized>
					</Typography>

					<IconButton
						onClick={openEditor}
						sx={[
							(sectionDesriptionEdit || Boolean(props.viewOnly)) && {
								visibility: "hidden",
							},
						]}
					>
						<EditIcon />
					</IconButton>
				</Stack>
			);
		}

		if (props.viewOnly) {
			return <></>;
		}

		return (
			<div>
				<Button color="primary" onClick={openEditor}>
					<Localized id="learning-material-button-add-description">
						+ Add description
					</Localized>
				</Button>
			</div>
		);
	}

	return (
		<Stack>
			<DescriptionTitle />

			{sectionDesriptionEdit ? (
				<SectionDescriptionEditor
					exercise={{
						title: props.sectionTitle,
						content: sectionDescription,
					}}
					courseId={courseId}
					chapterId={chapterId}
					sectionId={sectionId}
					onClose={closeDescriptionEditor}
				/>
			) : (
				<ExerciseQuestion text={sectionDescription} />
			)}
		</Stack>
	);
}

function Exercises(props: {
	organisationName: string;
	courseId: number;
	sectionId: number;

	sectionKey: string;
	sectionNumber: number;

	individualSubmission?: boolean;
	individualMaxScore?: boolean;
	deleteionDisabled?: boolean;
	settingsEditingDisabled?: boolean;

	viewOnly?: boolean;

	openConfirmDialog: OpenConfirmationDialog;
}) {
	const subsectionKeys = useAppSelector((state) =>
		selectSubsectionKeys(state, props.sectionKey)
	);

	const showSnackbar = useSnackbar();

	return (
		<>
			{subsectionKeys.map((exerciseKey) => (
				<Fragment key={exerciseKey}>
					<Divider sx={{ml: -3, mr: -6}} />

					<Box>
						<LearningMaterialExerciseContent
							organisationName={props.organisationName}
							exerciseKey={exerciseKey}
							courseId={props.courseId}
							sectionId={props.sectionId}
							sectionNumber={props.sectionNumber}
							viewOnly={props.viewOnly}
							individualSubmission={props.individualSubmission}
							individualMaxScore={props.individualMaxScore}
							deletionDisabled={props.deleteionDisabled}
							settingsEditingDisabled={props.settingsEditingDisabled}
							showSnackbar={showSnackbar}
							openConfirmDialog={props.openConfirmDialog}
						/>
					</Box>
				</Fragment>
			))}
		</>
	);
}

const SectionExercises = React.memo(Exercises);

function NewExerciseEditor(props: {
	organisationName: string;
	courseId: number;
	chapterId: number;
	sectionId: number;

	exerciseType?: ExerciseType;

	onClose: (updated: boolean) => void;
	onSave: (exercise: EditableExercise) => Promise<number>;
}) {
	const dispatch = useAppDispatch();

	const endEditing = useCallback(() => {
		dispatch(editingEnded());
	}, [dispatch]);

	async function build(exerciseId: number, exercise: ProgExercise) {
		const result = await dispatch(
			buildProgExercise({
				courseId: props.courseId,
				exerciseId,
				exercise,
			})
		);

		return unwrapResult(result).buildResult;
	}

	const changeExampleOutput = useCallback(
		(exerciseId: number, exampleOutput: string) => {
			dispatch(
				exampleOutputChanged({
					exerciseId,
					exampleOutput,
				})
			);
		},
		[dispatch]
	);

	const editorProps = {
		organisationName: props.organisationName,
		courseId: props.courseId,
		exercise: null,
		onClose: props.onClose,
		onSave: props.onSave,
		onUnmount: endEditing,
	};

	switch (props.exerciseType) {
		case ExerciseType.Theory:
			return (
				<TheoryExerciseEditor
					courseId={props.courseId}
					chapterId={props.chapterId}
					sectionId={props.sectionId}
					onClose={props.onClose}
				/>
			);
		case ExerciseType.Open:
			return <OpenExerciseEditor {...editorProps} />;
		case ExerciseType.Multi:
			return <MultiExerciseEditor {...editorProps} />;
		case ExerciseType.Math:
			return <MathExerciseEditor {...editorProps} />;
		case ExerciseType.External:
			return <ExternalExerciseEditor {...editorProps} />;
		case ExerciseType.Prog:
			return (
				<ProgExerciseEditor
					{...editorProps}
					onBuild={build}
					onExampleOutputChanged={changeExampleOutput}
				/>
			);
		default:
			return <></>;
	}
}

function settings<
	T extends {selectionSize: number; maxScorePerExercise: number}
>(section: T) {
	return {
		selectionSize: section.selectionSize,
		maxScorePerExercise: section.maxScorePerExercise,
	};
}

export default React.memo(SectionContentManagement);
