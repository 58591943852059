import {Chip} from "@mui/material";
import type {ChipProps} from "@mui/material";
import {alpha} from "@mui/material/styles";
import React from "react";

type ChoiceChipProps = Omit<ChipProps, "color" | "variant"> & {
	selected: boolean;
};

function ChoiceChip(props: ChoiceChipProps) {
	const {selected, ...chip} = props;

	return (
		<Chip
			{...chip}
			color={selected ? "secondary" : undefined}
			clickable={chip.clickable ?? true}
			variant="outlined"
			sx={[
				selected && {
					"&, &:focus": (theme) => ({
						backgroundColor: alpha(
							theme.palette.secondary.main,
							theme.palette.action.selectedOpacity
						),
						color: theme.palette.text.primary,
					}),
				},
			]}
		/>
	);
}

export default ChoiceChip;
export type {ChoiceChipProps};
