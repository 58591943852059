import {Localized, useLocalization} from "@fluent/react";
import {Box, Table, TableBody, TableCell, TableContainer} from "@mui/material";
import type {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";

import createTagSearchCriteria from "./createTagSearchCriteria";
import ExerciseBankFilters from "./ExerciseBankFilters";
import ExerciseBankTableRow from "./ExerciseBankTableRow";
import ExerciseBankToolbar from "./ExerciseBankToolbar";
import type {ExerciseSearchCriteria} from "./ExerciseSearchCriteria";
import ExerciseSearchScope from "./ExerciseSearchScope";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import useMobileMode from "../../hooks/useMobileMode";
import useNavBarHeight from "../../hooks/useNavBarHeight";
import selectCourse from "../../store/courses/selectCourse";
import ExerciseType from "../../store/exercises/ExerciseType";
import Feature from "../../store/features/Feature";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import {useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import type {ExerciseSearchResult} from "../../store/services/exerciseService";
import exerciseService from "../../store/services/exerciseService";
import useSnackbar from "../../store/ui/useSnackbar";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import useDeleteAction from "./useDeleteAction";
import ExtendableBox from "../../utils/ExtendableBox";
import HeadCell from "../../utils/tables/HeadCell";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";
import SortingHeader from "../../utils/tables/SortingHeader";
import SortOrder from "../../utils/tables/SortOrder";
import TablePagination from "../../utils/tables/TablePagination";
import usePaginationState from "../../utils/tables/usePaginationState";

const filtersBarWidth = 320;

const useStyles = makeStyles<Theme, {navBarHeight: number}>((theme) =>
	createStyles({
		root: {
			display: "flex",
			background: theme.palette.background.paper,
		},
		container: {
			flexGrow: 1,
			marginTop: theme.spacing(2),
			height: "100%",
		},
	})
);

const headCells: HeadCell<ExerciseSearchResult>[] = [
	{
		id: "title",
		label: "exercise-bank-table-heading-title",
		sortable: true,
	},
	{
		id: "type",
		label: "exercise-bank-table-heading-type",
		sortable: false,
		width: 210,
	},
	{
		id: "authorName",
		label: "exercise-bank-table-heading-author",
		sortable: true,
		width: 210,
	},
	{
		id: "created",
		label: "exercise-bank-table-heading-created",
		sortable: true,
		width: 210,
		align: "right",
	},
];

const columnNumber = headCells.length + 1;

const ExerciseBank = ({
	organisationName,
	viewOnly,
}: {
	organisationName: string;
	viewOnly?: boolean;
}): JSX.Element => {
	const navBarHeight = useNavBarHeight();
	const classes = useStyles({navBarHeight});

	const [featureEnabled] = useFeatureEnabled();
	const externalEnabled = featureEnabled(Feature.ExternalExercises);
	const tagsEnabled = featureEnabled(Feature.ExerciseTags);

	const {id} = useParams<{id: string}>();
	const courseId = Number(id);
	const course = useAppSelector((state) =>
		selectCourse(state, keyProvider.course(courseId))
	);
	const core = course?.core;
	const userId = useAppSelector(selectUserId);

	const [openFilters, setOpenFilters] = useState(false);
	const closeFilters = useCallback(() => setOpenFilters(false), []);

	const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);
	const [sortField, setSortField] = useState<keyof ExerciseSearchResult>(
		"title"
	);

	const [exercisesPerPage, setExercisesPerPage] = useState(10);

	const {
		page: exercisesPage,
		pageFetchStatus,
		fetchFirstPage,
		fetchRelatedPage: fetchExercisePage,
		reloadPage,
		retryFetching,
	} = usePaginationState<ExerciseSearchResult>();

	const exercises = exercisesPage.content;

	const showSnackbar = useSnackbar();
	const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog();

	const [searchCriteria, setSearchCriteria] = useState<ExerciseSearchCriteria>(
		() => ({
			scope: ExerciseSearchScope.Course,
			language: course?.language ?? "en",
		})
	);

	const availableExerciseTypes = useMemo(
		() =>
			course?.availableExerciseTypes?.filter(
				(type) => externalEnabled || type !== ExerciseType.External
			) ?? [],
		[course?.availableExerciseTypes, externalEnabled]
	);

	const changeOrder = (
		orderBy: keyof ExerciseSearchResult,
		order: SortOrder
	) => {
		setSortOrder(order);
		setSortField(orderBy);
	};

	const {l10n} = useLocalization();
	const mobileMode = useMobileMode("md");

	const [deleteExercise, deletionErrorDialog] = useDeleteAction(
		reloadPage,
		openConfirmationDialog,
		showSnackbar
	);

	useEffect(() => {
		if (!core) {
			return;
		}

		fetchFirstPage(() =>
			exerciseService.searchExercises(
				{
					core: core,
					language: searchCriteria.language,
					exerciseType: searchCriteria.type ?? undefined,
					query: searchCriteria.query,
					courseId:
						searchCriteria.scope === ExerciseSearchScope.Course
							? courseId
							: undefined,
					organisationName:
						searchCriteria.scope === ExerciseSearchScope.Organisation
							? organisationName
							: undefined,
					userId:
						searchCriteria.scope === ExerciseSearchScope.Personal
							? userId
							: undefined,
					tags: searchCriteria.tags,
				},
				{field: sortField, descending: sortOrder === SortOrder.Desc},
				exercisesPerPage
			)
		);
	}, [
		core,
		courseId,
		exercisesPerPage,
		fetchFirstPage,
		organisationName,
		searchCriteria.language,
		searchCriteria.query,
		searchCriteria.scope,
		searchCriteria.tags,
		searchCriteria.type,
		sortField,
		sortOrder,
		userId,
	]);

	const searchTags = useCallback(
		async (prefix: string, pageSize: number) => {
			const criteria = createTagSearchCriteria(
				searchCriteria.scope,
				courseId,
				userId,
				organisationName
			);

			const page = await exerciseService.searchTags(
				{
					...criteria,
					prefix,
				},
				pageSize
			);

			return page;
		},
		[courseId, searchCriteria.scope, organisationName, userId]
	);

	return (
		<div className={classes.root}>
			<ExtendableBox
				rightExtension={openFilters || mobileMode ? 0 : filtersBarWidth}
				minWidth={0}
			>
				<Box
					display="flex"
					flexDirection="column"
					pt={mobileMode ? 3 : 5}
					px={mobileMode ? 3 : 6}
					pb={1.5}
					position="relative"
					style={{height: `calc(100vh - ${navBarHeight + 1}px)`}}
				>
					<ExerciseBankToolbar
						availableExerciseTypes={availableExerciseTypes}
						courseId={courseId}
						criteria={searchCriteria}
						mainActionHidden={viewOnly}
						onCriteriaChange={setSearchCriteria}
						onToggleFilters={() => setOpenFilters((prev) => !prev)}
					/>

					<TableContainer className={classes.container}>
						<Table stickyHeader>
							<SortingHeader
								onOrderChange={changeOrder}
								order={sortOrder}
								orderBy={sortField}
								headCells={headCells}
								rightAnnex={<TableCell />}
								loading={pageFetchStatus === "pending"}
							/>
							<TableBody>
								{pageFetchStatus === "failed" && (
									<LoadingErrorState
										description={
											<Localized id="exercise-bank-loading-error-descr">
												Something has gone wrong, and we cannot load exercises
											</Localized>
										}
										columnNumber={columnNumber}
										onReload={retryFetching}
									/>
								)}
								{pageFetchStatus === "succeeded" && exercises.length === 0 && (
									<NoSearchResultsState
										columnNumber={columnNumber}
										title={
											<Localized id="exercise-bank-no-results">
												No exercises
											</Localized>
										}
										description={
											<Localized id="exercise-bank-no-results-descr">
												No exercises were found matching your search criteria.
												Try to adjust filters
											</Localized>
										}
									/>
								)}
								{pageFetchStatus !== "failed" &&
									exercises.map((exercise) => (
										<ExerciseBankTableRow
											key={exercise.id}
											row={exercise}
											courseId={courseId}
											userId={userId}
											viewOnly={viewOnly}
											deleteExercise={deleteExercise}
											showSnackbar={showSnackbar}
											openConfirmationDialog={openConfirmationDialog}
										/>
									))}
							</TableBody>
						</Table>
					</TableContainer>
					<Box display="flex" justifyContent="flex-end" alignItems="center">
						<TablePagination
							onPageChange={fetchExercisePage}
							pageSize={exercisesPerPage}
							onPageSizeChange={setExercisesPerPage}
							first={Boolean(exercisesPage.request.first)}
							last={Boolean(exercisesPage.request.last)}
							next={Boolean(exercisesPage.request.next)}
							previous={Boolean(exercisesPage.request.previous)}
							label={l10n.getString(
								"exercise-bank-table-pagination-exercises-per-page",
								null,
								"Exercises per page"
							)}
							disabled={pageFetchStatus !== "succeeded"}
						/>
					</Box>
				</Box>
			</ExtendableBox>

			<ExerciseBankFilters
				open={openFilters}
				width={filtersBarWidth}
				mobileMode={mobileMode}
				onClose={closeFilters}
				availableExerciseTypes={availableExerciseTypes}
				criteria={searchCriteria}
				searchTags={tagsEnabled ? searchTags : undefined}
				onCriteriaChange={setSearchCriteria}
			/>

			{confirmationDialog}
			{deletionErrorDialog}
		</div>
	);
};

export default ExerciseBank;
