import {Localized} from "@fluent/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {Box, Button, Skeleton, Typography, useTheme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {SimpleTreeView} from "@mui/x-tree-view";
import React, {useEffect, useMemo, useState} from "react";
import {
	DragDropContext,
	Draggable,
	DropResult,
	Droppable,
} from "react-beautiful-dnd";
import {useHistory, useRouteMatch} from "react-router-dom";

import {draftKey} from "../../store/chapters/Chapter";
import SectionDraggable from "./SectionDraggable";
import StyledTreeItem from "./TreeItem";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import type ChapterStructure from "../../store/chapters/ChapterStructure";
import {getChapterContent} from "../../store/chapters/getChapterContent";
import makeSelectChapterStructure from "../../store/chapters/makeSelectChapterStructure";
import selectChapter from "../../store/chapters/selectChapter";
import updateChapterStructure from "../../store/chapters/updateChapterStructure";
import type {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import type {ShowSnackbar} from "../../store/ui/useSnackbar";
import DragIndicator from "./DragIndicator";
import {ChapterHasOldStructureWarning} from "../learningMaterial/CourseChaptersHaveOldStructureWarning";
import selectChapterEditing from "../../store/ui/selectChapterEditing";
import selectChapterContentStatus from "../../store/chapters/selectChapterContentStatus";

const useStyles = makeStyles((theme) =>
	createStyles({
		treeRoot: {
			flexGrow: 1,
		},
		chapterDragHandle: {
			visibility: "hidden",
			alignSelf: "flex-start",
			paddingTop: theme.spacing(1.5),
		},
		chapterItem: {
			"&:hover $chapterDragHandle": {
				visibility: "visible",
			},
		},
	})
);

function move<T>(list: T[], from: number, to: number): T[] {
	const result = [...list];
	const [removed] = result.splice(from, 1);
	result.splice(to, 0, removed);
	return result;
}

const DraggableComponent = (props: {
	chapterKey: string;
	index: number;
	expanded: boolean;
	courseEditing: boolean;
	viewOnly?: boolean;
	onToggle: () => void;
	openConfirmDialog: OpenConfirmationDialog;
	showSnackbar: ShowSnackbar;
}): JSX.Element => {
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const selectChapterStructure = useMemo(makeSelectChapterStructure, []);

	const {chapterKey, index, expanded, onToggle, courseEditing} = props;

	const chapter = useAppSelector((state) => selectChapter(state, chapterKey));

	const chapterEditing = useAppSelector((state) =>
		selectChapterEditing(state, chapterKey)
	);

	const structure = useAppSelector((state) =>
		selectChapterStructure(state, chapterKey)
	);

	const contentStatus = useAppSelector((state) =>
		selectChapterContentStatus(state, chapterKey)
	);

	const [cache, setCache] = useState<ChapterStructure>([]);

	useEffect(() => {
		setCache(structure);
	}, [structure]);

	const chapterIsDraft = chapterKey === draftKey;

	const structureSupported = chapter?.structureType === "sections";

	const haveToRequestContent =
		expanded &&
		contentStatus === "none" &&
		!chapterIsDraft &&
		structureSupported;

	useEffect(() => {
		if (chapter && haveToRequestContent) {
			dispatch(
				getChapterContent({
					courseId: chapter.courseId,
					chapterId: chapter.id,
				})
			);
		}
	}, [chapter, dispatch, haveToRequestContent]);

	if (!chapter) {
		return <></>;
	}

	const courseId = chapter.courseId;

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		const sourceIndex = result.source.index;
		const destIndex = result.destination.index;

		if (result.type === "section") {
			const structure = move(cache, sourceIndex, destIndex);

			setCache(structure);

			dispatch(
				updateChapterStructure({
					courseId: courseId,
					chapterId: chapter.id,
					structure: structure,
				})
			);
		} else if (result.type === "subsection") {
			if (result.destination.droppableId === result.source.droppableId) {
				const structure = [...cache];

				const i = structure.findIndex(
					(s) => s.sectionKey === result.source.droppableId
				);

				structure[i] = {
					...structure[i],
					subsectionKeys: move(
						structure[i].subsectionKeys,
						sourceIndex,
						destIndex
					),
				};

				setCache(structure);

				dispatch(
					updateChapterStructure({
						courseId: courseId,
						chapterId: chapter.id,
						structure: structure,
					})
				);
			} else {
				const structure = [...cache];

				const from = structure.findIndex(
					(s) => s.sectionKey === result.source.droppableId
				);

				const to = structure.findIndex(
					(s) => s.sectionKey === result.destination?.droppableId
				);

				structure[from] = {
					...structure[from],
					subsectionKeys: [...structure[from].subsectionKeys],
				};

				const [subsection] = structure[from].subsectionKeys.splice(
					sourceIndex,
					1
				);

				structure[to] = {
					...structure[to],
					subsectionKeys: [...structure[to].subsectionKeys],
				};

				structure[to].subsectionKeys.splice(destIndex, 0, subsection);

				setCache(structure);

				dispatch(
					updateChapterStructure({
						courseId: courseId,
						chapterId: chapter.id,
						structure: structure,
					})
				);
			}
		}
	};

	const Sections = () => {
		if (contentStatus === "pending") {
			return <ChapterLoading />;
		}

		if (cache.length === 0) {
			return (
				<ChapterIsEmpty chapterId={chapter.id} viewOnly={props.viewOnly} />
			);
		}

		return (
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId={chapterKey} type="section">
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{cache.map((section, index) => (
								<SectionDraggable
									key={section.sectionKey}
									courseId={courseId}
									chapterId={chapter.id}
									sectionKey={section.sectionKey}
									subsectionKeys={section.subsectionKeys}
									index={index}
									deletionDisabled={Boolean(chapter.startDate)}
									chapterEditing={chapterEditing}
									viewOnly={props.viewOnly}
									openConfirmDialog={props.openConfirmDialog}
									showSnackbar={props.showSnackbar}
								/>
							))}

							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		);
	};

	return (
		<Draggable
			draggableId={chapterKey}
			index={index}
			isDragDisabled={courseEditing || props.viewOnly}
		>
			{(provided) => (
				<div ref={provided.innerRef} {...provided.draggableProps}>
					<Box display="flex" className={classes.chapterItem}>
						{!props.viewOnly && (
							<DragIndicator
								dragHandleProps={provided.dragHandleProps}
								className={classes.chapterDragHandle}
							/>
						)}
						<SimpleTreeView
							onItemExpansionToggle={onToggle}
							expandedItems={[expanded ? `${chapter.id}` : ""]}
							selectedItems={null}
							slots={{
								collapseIcon: CollapseIcon,
								expandIcon: ExpandIcon,
								endIcon: EndIcon,
							}}
							sx={{
								flexGrow: 1,
							}}
						>
							<StyledTreeItem
								itemId={`${chapter.id}`}
								number={chapter.number}
								labelText={chapter.title}
								startDate={chapter.startDate}
								canAddSection={chapter.structureType === "sections"}
								chapterId={chapter.id}
								courseId={courseId}
								courseEditing={courseEditing}
								chapterEditing={chapterEditing}
								draft={chapterIsDraft}
								viewOnly={props.viewOnly}
								openConfirmDialog={props.openConfirmDialog}
								showSnackbar={props.showSnackbar}
							>
								{structureSupported ? (
									<Sections />
								) : (
									<ChapterHasOldStructureWarning courseId={courseId} />
								)}
							</StyledTreeItem>
						</SimpleTreeView>
					</Box>
				</div>
			)}
		</Draggable>
	);
};

function ExpandIcon() {
	return <ExpandMoreIcon color="primary" />;
}

function CollapseIcon() {
	return <ExpandLessIcon color="primary" />;
}

function EndIcon() {
	return <span style={{width: 24}} />;
}

function ChapterLoading() {
	const theme = useTheme();

	function SectionPlaceholder() {
		return (
			<Box display="flex" style={{gap: theme.spacing(2)}}>
				<Typography variant="body2" component="div" style={{width: "2ch"}}>
					<Skeleton variant="text" />
				</Typography>
				<Typography variant="body2" component="div" style={{width: "100%"}}>
					<Skeleton variant="text" />
				</Typography>
			</Box>
		);
	}

	function ExercisePlaceholder() {
		return (
			<Box display="flex" mt={3} ml={2} style={{gap: theme.spacing(2)}}>
				<Typography variant="body2" component="div" style={{width: "3ch"}}>
					<Skeleton variant="text" />
				</Typography>
				<Typography variant="body2" component="div" style={{width: "100%"}}>
					<Skeleton variant="text" />
				</Typography>
			</Box>
		);
	}

	return (
		<Box display="flex" flexDirection="column" py={1.5} ml={3} mr={6}>
			<SectionPlaceholder />
			<ExercisePlaceholder />
			<ExercisePlaceholder />
		</Box>
	);
}

function ChapterIsEmpty(props: {chapterId: number; viewOnly?: boolean}) {
	const {url: baseUrl} = useRouteMatch();
	const history = useHistory();

	const addSection = () => {
		history.push(baseUrl + `/chapters/${props.chapterId}/sections/new`);
	};

	return (
		<Box display="flex" flexDirection="column" alignItems="center" py={4}>
			<Typography variant="subtitle2">
				<Localized id="learning-material-content-empty-chapter">
					Chapter is empty
				</Localized>
			</Typography>
			{!props.viewOnly && (
				<Button
					variant="text"
					color="primary"
					disabled={!props.chapterId}
					onClick={addSection}
				>
					<Localized id="dragdrop-chapter-add-section">Add section</Localized>
				</Button>
			)}
		</Box>
	);
}

export default DraggableComponent;
