import {Localized, useLocalization} from "@fluent/react";
import {Box, Button, Stack, TextField, debounce} from "@mui/material";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useCallback, useEffect, useMemo, useState} from "react";

import AnswerVisibilitySelector from "./AnswerVisibilitySelector";
import ExercisePlaceholder from "../content/ExercisePlaceholder";
import ExerciseQuestion from "../content/exercises/ExerciseQuestion";
import ExerciseTitle from "../content/exercises/ExerciseTitle";
import ExternalResponseArea from "../content/exercises/external/ResponseArea";
import MathSolutionForm from "../content/exercises/math/MathSolutionForm";
import MultiResponseAreaSelector from "../content/exercises/multi/MultiResponseAreaSelector";
import OpenResponseArea from "../content/exercises/open/ResponseArea";
import ProgResponseArea from "../content/exercises/prog/ResponseArea";
import ExerciseEditorPlaceholder from "./ExerciseEditorPlaceholder";
import ExternalExerciseEditor from "./ExternalExerciseEditor";
import type {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import MathExerciseEditor from "./MathExerciseEditor";
import MultiExerciseEditor from "./MultiExerciseEditor";
import OpenExerciseEditor from "./OpenExerciseEditor";
import ProgExerciseEditor from "./ProgExerciseEditor";
import AnswerButton from "../sidebars/exerciseAnswers/AnswerButton";
import AnswerVisibility from "../../store/chapterExercises/AnswerVisibility";
import {makeSelectChapterExerciseWithContent} from "../../store/chapterExercises/makeSelectChapterExercise";
import updateChapterExerciseSettings from "../../store/chapterExercises/updateChapterExerciseSettings";
import selectChapter from "../../store/chapters/selectChapter";
import CourseType from "../../store/courses/CourseType";
import selectCourse from "../../store/courses/selectCourse";
import {
	DraftType,
	editingEnded,
	editingStarted,
} from "../../store/exercises/exericseDraftSlice";
import ExerciseType from "../../store/exercises/ExerciseType";
import buildProgExercise from "../../store/exercises/prog/buildExercise";
import exampleOutputChanged from "../../store/exercises/prog/exampleOutputChanged";
import {saveExerciseDraft} from "../../store/exercises/saveExerciseDraft";
import selectExerciseDraft from "../../store/exercises/selectExerciseDraft";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import type {
	EditableExercise,
	ProgExercise,
} from "../../store/services/dtos/EditableExercise";
import exerciseService from "../../store/services/exerciseService";
import type Feedback from "../../store/studentResponses/Feedback";
import type SubmissionResult from "../../store/studentResponses/SubmissionResult";
import type {ShowSnackbar} from "../../store/ui/useSnackbar";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import TheoryExerciseEditor from "./TheoryExerciseEditor";
import useRemoveSubsectionAction from "./useRemoveSubsectionAction";

type Props = {
	organisationName: string;
	exerciseKey: string;
	courseId: number;
	sectionId: number;
	sectionNumber: number;

	viewOnly?: boolean;
	individualMaxScore?: boolean;
	individualSubmission?: boolean;
	deletionDisabled?: boolean;
	settingsEditingDisabled?: boolean;

	openConfirmDialog: OpenConfirmationDialog;
	showSnackbar: ShowSnackbar;
};

function LearningMaterialExerciseContent(props: Props) {
	const {courseId, sectionId, exerciseKey, showSnackbar} = props;

	const dispatch = useAppDispatch();
	const {l10n} = useLocalization();

	const selectExercise = useMemo(makeSelectChapterExerciseWithContent, []);

	const exercise = useAppSelector((state) =>
		selectExercise(state, exerciseKey)
	);
	const exerciseId = exercise?.id;

	const chapterId = exercise?.chapterId;

	const courseKey = keyProvider.course(courseId);

	const courseAnswerVisibility = useAppSelector(
		(state) =>
			selectCourse(state, courseKey)?.answerVisibility ?? AnswerVisibility.Never
	);

	const chapterKey = chapterId && keyProvider.chapter(chapterId);

	const chapterAnswerVisibility = useAppSelector(
		(state) =>
			(chapterKey && selectChapter(state, chapterKey)?.answerVisibility) ||
			courseAnswerVisibility
	);

	const courseType = useAppSelector(
		(state) => selectCourse(state, courseKey)?.type
	);

	const patchExerciseSettings = useCallback(
		async (settings: {
			maxScore?: number;
			answerVisibility?: AnswerVisibility | null;
		}) => {
			if (!chapterId || !exerciseId) {
				return;
			}

			const res = await dispatch(
				updateChapterExerciseSettings({
					courseId,
					chapterId,
					exerciseId,
					settings,
				})
			);

			try {
				unwrapResult(res);
			} catch (error) {
				showSnackbar("error", l10n.getString("error-general"));
				throw error;
			}
		},
		[courseId, chapterId, exerciseId, dispatch, l10n, showSnackbar]
	);

	const [
		editableExercise,
		setEditableExercise,
	] = useState<EditableExercise | null>(null);

	const userId = useAppSelector(selectUserId);

	const draft = useAppSelector(selectExerciseDraft);

	const [editorOpen, setEditorOpen] = useState(false);

	useEffect(() => {
		if (editorOpen && exerciseId && exercise?.type !== ExerciseType.Theory) {
			(async () => {
				const res = await exerciseService.getExercise(exerciseId);
				setEditableExercise(res);
			})();
		}
	}, [editorOpen, exercise?.type, exerciseId]);

	const handleEditClick = useCallback(() => {
		if (!exercise) {
			return;
		}

		setEditorOpen(true);

		dispatch(
			editingStarted({
				id: exercise.id,
				type: DraftType.Exercise,
				title: exercise.title,
				content: exercise.question,
				exerciseType: exercise.type,
			})
		);
	}, [dispatch, exercise]);

	const endEditing = useCallback(() => {
		dispatch(editingEnded());
		setEditableExercise(null);
	}, [dispatch]);

	const additionalActions = useMemo(() => {
		return [<AnswerButton key="answer" chapterExerciseKey={exerciseKey} />];
	}, [exerciseKey]);

	const changeExampleOutput = useCallback(
		(exerciseId: number, exampleOutput: string) => {
			dispatch(
				exampleOutputChanged({
					exerciseId,
					exampleOutput,
				})
			);
		},
		[dispatch]
	);

	const removeSubsection = useRemoveSubsectionAction(
		courseId,
		props.openConfirmDialog,
		showSnackbar,
		l10n
	);

	if (!exercise) {
		return <ExercisePlaceholder />;
	}

	const closeEditor = () => {
		setEditorOpen(false);
	};

	const saveEdited = async (editableExercise: EditableExercise) => {
		const result = await dispatch(
			saveExerciseDraft({
				courseId,
				chapterId: exercise.chapterId,
				sectionId: sectionId,
				exercise: editableExercise,
			})
		);

		return unwrapResult(result).id;
	};

	const build = async (exerciseId: number, exercise: ProgExercise) => {
		const result = await dispatch(
			buildProgExercise({
				courseId,
				exerciseId,
				exercise,
			})
		);

		return unwrapResult(result).buildResult;
	};

	const switchExerciseEditor = () => {
		if (exercise.type === ExerciseType.Theory) {
			return (
				<TheoryExerciseEditor
					onClose={closeEditor}
					courseId={courseId}
					chapterId={exercise.chapterId}
					sectionId={sectionId}
				/>
			);
		}

		if (!editableExercise) {
			return <ExerciseEditorPlaceholder />;
		}

		const editorProps = {
			organisationName: props.organisationName,
			courseId,
			exerciseId,
			exercise: editableExercise,
			onClose: closeEditor,
			onSave: saveEdited,
			onUnmount: endEditing,
		};

		switch (exercise.type) {
			case ExerciseType.Open:
				return <OpenExerciseEditor {...editorProps} />;
			case ExerciseType.Multi:
				return <MultiExerciseEditor {...editorProps} />;
			case ExerciseType.Math:
				return <MathExerciseEditor {...editorProps} />;
			case ExerciseType.External:
				return <ExternalExerciseEditor {...editorProps} />;
			case ExerciseType.Prog:
				return (
					<ProgExerciseEditor
						{...editorProps}
						onBuild={build}
						onExampleOutputChanged={changeExampleOutput}
					/>
				);
			default:
				return <></>;
		}
	};

	if (editorOpen) {
		return switchExerciseEditor();
	}

	const editingAllowed = exercise.originId === courseId;

	const responseAreaProps = {
		response: null,
		readonly: true,
		submitting: false,
		additionalActions,
		onSave: save,
		onSubmit: props.individualSubmission ? submit : undefined,
	};

	let responseArea = null;

	switch (exercise.type) {
		case ExerciseType.Multi:
			responseArea = (
				<MultiResponseAreaSelector
					id={`response-area-${exercise.id}`}
					subtype={exercise.subtype}
					interactions={exercise.interactions}
					{...responseAreaProps}
				/>
			);

			break;
		case ExerciseType.Open:
			responseArea = (
				<OpenResponseArea
					subtype={exercise.subtype}
					emptyResponseAllowed={false}
					selfAssessment={false}
					chapterId={exercise.chapterId}
					courseId={courseId}
					userId={userId}
					exerciseId={exercise.id}
					createFileUploader={createFileUploader}
					{...responseAreaProps}
				/>
			);

			break;
		case ExerciseType.Math:
			responseArea = (
				<MathSolutionForm
					subtype={exercise.subtype}
					interactions={exercise.interactions}
					{...responseAreaProps}
				/>
			);

			break;
		case ExerciseType.Prog:
			responseArea = (
				<ProgResponseArea
					interactions={exercise.interactions}
					openConfirmDialog={props.openConfirmDialog}
					{...responseAreaProps}
				/>
			);

			break;
		case ExerciseType.External:
			responseArea = (
				<ExternalResponseArea
					subtype={exercise.subtype}
					interactions={exercise.interactions}
					onPostOutcomes={postOutcomes}
					{...responseAreaProps}
				/>
			);

			break;
	}

	const editing = draft !== null;

	return (
		<>
			<Stack
				direction="row"
				sx={{mb: 3, justifyContent: "space-between", alignItems: "center"}}
			>
				{exercise.type !== ExerciseType.Theory && (
					<SettingsEditor
						maxScore={exercise.maxScore}
						answerVisibility={exercise.answerVisibility ?? null}
						courseType={courseType}
						inheritedAnswerVisibility={chapterAnswerVisibility}
						individualMaxScore={props.individualMaxScore}
						viewOnly={props.settingsEditingDisabled}
						onUpdate={patchExerciseSettings}
					/>
				)}

				{!props.viewOnly && (
					<Stack
						direction="row"
						spacing={3}
						sx={[
							{justifyContent: "flex-end"},
							exercise.type === ExerciseType.Theory && {flexGrow: 1},
						]}
					>
						<Button
							color="primary"
							disabled={editing || props.deletionDisabled}
							onClick={() => removeSubsection(exerciseKey, exercise)}
						>
							<Localized id="learning-material-exercise-content-button-delete">
								Delete
							</Localized>
						</Button>
						{editingAllowed && (
							<Button
								color="primary"
								variant="contained"
								disabled={editing}
								onClick={handleEditClick}
							>
								<Localized id="learning-material-exercise-content-button-edit">
									Edit
								</Localized>
							</Button>
						)}
					</Stack>
				)}
			</Stack>

			<ExerciseTitle
				sectionNumber={props.sectionNumber}
				exerciseNumber={exercise.number}
				title={exercise.title}
				exerciseId={exercise.id}
			/>

			<ExerciseQuestion text={exercise.question || ""} />

			<Box mt={4}>{responseArea}</Box>
		</>
	);
}

function SettingsEditor(props: {
	maxScore: number;
	answerVisibility: AnswerVisibility | null;

	courseType?: CourseType;
	inheritedAnswerVisibility: AnswerVisibility;

	viewOnly?: boolean;
	individualMaxScore?: boolean;

	onUpdate: (patch: {
		maxScore?: number;
		answerVisibility?: AnswerVisibility | null;
	}) => Promise<void>;
}) {
	const {
		answerVisibility: savedAnswerVisibility,
		maxScore: savedMaxScore,
		onUpdate,
	} = props;

	const [maxScore, setMaxScore] = useState(savedMaxScore);

	const [answerVisibility, setAnswerVisibility] = useState(
		savedAnswerVisibility
	);

	useEffect(() => {
		if (
			savedMaxScore === maxScore &&
			savedAnswerVisibility === answerVisibility
		) {
			return noop;
		}

		const update = debounce(() => {
			const p: Parameters<typeof onUpdate>[0] = {};

			if (savedMaxScore !== maxScore) {
				p.maxScore = maxScore;
			}

			if (savedAnswerVisibility !== answerVisibility) {
				p.answerVisibility = answerVisibility;
			}

			onUpdate(p).catch(() => {
				setMaxScore(savedMaxScore);
				setAnswerVisibility(savedAnswerVisibility);
			});
		}, 500);

		update();

		return update.clear;
	}, [
		answerVisibility,
		maxScore,
		onUpdate,
		savedAnswerVisibility,
		savedMaxScore,
	]);

	return (
		<Stack spacing={3} direction="row" sx={{flexGrow: 1}}>
			<Box sx={{minWidth: "10rem"}}>
				<AnswerVisibilitySelector
					courseType={props.courseType}
					inheritedValue={props.inheritedAnswerVisibility}
					value={answerVisibility ?? ""}
					disabled={props.viewOnly}
					onChange={(v) => {
						setAnswerVisibility(v !== "" ? v : null);
					}}
				/>
			</Box>
			{props.individualMaxScore && (
				<TextField
					type="number"
					value={String(maxScore)}
					onChange={({target}) => {
						const score = parseInt(target.value);
						if (score >= 0) {
							setMaxScore(score);
						}
					}}
					disabled={props.viewOnly || true}
					slotProps={{
						htmlInput: {min: 0},
						inputLabel: {shrink: true, sx: {whiteSpace: "nowrap"}},
					}}
					label={
						<Localized id="content-exercise-max-score">Max score</Localized>
					}
					sx={{width: "7rem"}}
				/>
			)}
		</Stack>
	);
}

function save(): Promise<Feedback> {
	throw new Error("This function should not be called.");
}

function submit(): Promise<SubmissionResult> {
	throw new Error("This function should not be called.");
}

async function postOutcomes(): Promise<void> {
	return;
}

function createFileUploader() {
	return null;
}

function noop() {
	return;
}

export default LearningMaterialExerciseContent;
