import {Localized} from "@fluent/react";
import {MenuItem, TextField} from "@mui/material";
import React from "react";

import ExerciseSearchScope from "./ExerciseSearchScope";

const scopes = [
	{
		value: ExerciseSearchScope.Course,
		displayName: (
			<Localized id="exercise-bank-filter-search-scope-option-course-exercises">
				Course exercises
			</Localized>
		),
	},
	{
		value: ExerciseSearchScope.Personal,
		displayName: (
			<Localized id="exercise-bank-filter-search-scope-option-my-exercises">
				My exercises
			</Localized>
		),
	},
	{
		value: ExerciseSearchScope.Organisation,
		displayName: (
			<Localized id="exercise-bank-filter-search-scope-option-organisation-exercises">
				Organisation exercises
			</Localized>
		),
	},
	{
		value: ExerciseSearchScope.Public,
		displayName: (
			<Localized id="exercise-bank-filter-search-scope-option-public-exercises">
				Public exercices
			</Localized>
		),
	},
];

function SearchScopeSelector(props: {
	value: ExerciseSearchScope;
	onChange: (v: ExerciseSearchScope) => void;
}) {
	return (
		<TextField
			fullWidth
			select
			value={props.value}
			label={
				<Localized id="add-existing-exercises-dialog-search-scope-label">
					Search scope
				</Localized>
			}
			slotProps={{inputLabel: {shrink: true}}}
			onChange={({target}) => {
				props.onChange((target.value as unknown) as ExerciseSearchScope);
			}}
		>
			{scopes.map((scope, i) => (
				<MenuItem key={i} value={scope.value}>
					{scope.displayName}
				</MenuItem>
			))}
		</TextField>
	);
}

export default SearchScopeSelector;
