import {Localized, useLocalization} from "@fluent/react";
import React from "react";

import type {
	ChangeExerciseSearchCriteria,
	ExerciseSearchCriteria,
} from "./ExerciseSearchCriteria";
import ExerciseTypeChipSelector from "./ExerciseTypeChipSelector";
import type {Page} from "../../helpers/paginatedSearchHelpers";
import useStateWithDebounce from "../../hooks/useStateWithDebounce";
import ExerciseTagsSelector from "../learningMaterial/ExerciseTagsSelector";
import SearchScopeSelector from "./SearchScopeSelector";
import SidebarBody from "../sidebars/SidebarBody";
import SidebarHeader from "../sidebars/SidebarHeader";
import SidebarSection from "../sidebars/SidebarSection";
import type ExerciseType from "../../store/exercises/ExerciseType";
import type TagSearchResult from "../../store/exercises/TagSearchResult";
import AppBarDependentDrawer from "../../utils/AppBarDependentDrawer";
import ContentLanguageSelector from "../../utils/ContentLanguageSelector";

const ExerciseBankFilters = (props: {
	open: boolean;
	width: number;
	mobileMode?: boolean;
	onClose: () => void;
	availableExerciseTypes: ExerciseType[];
	criteria: ExerciseSearchCriteria;
	searchTags?: (
		prefix: string,
		pageSize: number
	) => Promise<Page<TagSearchResult>>;
	onCriteriaChange: ChangeExerciseSearchCriteria;
}): JSX.Element => {
	const {onCriteriaChange, searchTags} = props;

	const {l10n} = useLocalization();

	const [criteria, setCriteria] = useStateWithDebounce(
		props.criteria,
		onCriteriaChange
	);

	return (
		<AppBarDependentDrawer
			anchor="right"
			open={props.open}
			variant={props.mobileMode ? "temporary" : "persistent"}
			width={props.width}
			topFixedPanel={
				<SidebarHeader
					title={l10n.getString("exercise-bank-sidebar-header-title-filters")}
					onClose={props.onClose}
				/>
			}
		>
			<SidebarBody>
				<SidebarSection title="">
					<SearchScopeSelector
						value={criteria.scope}
						onChange={(val) => setCriteria((prev) => ({...prev, scope: val}))}
					/>
				</SidebarSection>
				<SidebarSection title="">
					<ContentLanguageSelector
						value={criteria.language}
						onChange={(language) =>
							setCriteria((prev) => ({...prev, language}))
						}
					/>
				</SidebarSection>
				<SidebarSection
					title={
						<Localized id="exercise-bank-filter-type-label">Type</Localized>
					}
				>
					<ExerciseTypeChipSelector
						availableExerciseTypes={props.availableExerciseTypes}
						value={criteria.type}
						onChange={(v) =>
							setCriteria((prev) => ({
								...prev,
								type: prev.type !== v ? v : undefined,
							}))
						}
					/>
				</SidebarSection>
				{searchTags && (
					<SidebarSection title="">
						<ExerciseTagsSelector
							value={criteria.tags ?? []}
							onChange={(tags) => {
								setCriteria((prev) => ({...prev, tags: tags}));
							}}
							searchTags={searchTags}
						/>
					</SidebarSection>
				)}
			</SidebarBody>
		</AppBarDependentDrawer>
	);
};

export default React.memo(ExerciseBankFilters);
