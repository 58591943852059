import {createAsyncThunk} from "@reduxjs/toolkit";

import type DashboardName from "./DashboardName";
import {keyProvider} from "../keyProvider";
import widgetService from "../services/widgetService";
import type WidgetSettings from "./WidgetSettings";

type Result = {
	dashboardName: DashboardName;
	courseKey: string;
	widgetKeys: string[];
	widgetSettings: {[key: string]: WidgetSettings};
};

const fetchWidgets = createAsyncThunk<
	Result,
	{dashboardName: DashboardName; userId: number; courseId: number}
>(
	"widgets/fetch",
	async ({dashboardName, userId, courseId}): Promise<Result> => {
		const widgets = await widgetService.getWidgets(
			userId,
			courseId,
			dashboardName
		);

		const widgetKeys = widgets.map((w) => w.name);

		const widgetSettings: {[key: string]: WidgetSettings} = {};
		widgets.forEach((w) => {
			widgetSettings[keyProvider.widgetSettings(courseId, w.name)] = w.settings;
		});

		return {
			courseKey: keyProvider.course(courseId),
			dashboardName,
			widgetKeys,
			widgetSettings,
		};
	}
);

export default fetchWidgets;
