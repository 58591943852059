import {Box} from "@mui/material";
import React, {useEffect, useRef} from "react";

import Widget from "../analytics/widget/Widget";
import WidgetBody from "../analytics/widget/WidgetBody";
import WidgetTitle from "../analytics/widget/WidgetTitle";
import {resolveLinkToOldSite} from "../../helpers/pathHelpers";
import useCurrentLocale from "../../i18n/useCurrentLocale";

function OverallProgressWidget(props: {
	id: string;
	title: React.ReactNode;
	studentId: number;
	courseId: number;
}) {
	return (
		<Widget id={props.id}>
			<WidgetTitle>{props.title}</WidgetTitle>
			<WidgetBody>
				<OverallProgress courseId={props.courseId} />
			</WidgetBody>
		</Widget>
	);
}

function OverallProgress(props: {courseId: number}) {
	const locale = useCurrentLocale();

	const frame = useRef<HTMLIFrameElement>(null);

	useEffect(() => {
		frame.current?.contentWindow?.location.replace(
			resolveLinkToOldSite(
				`/student/${props.courseId}/?langOvrd=${locale}&inWidget#/my_corner#overall-progress`
			)
		);
	}, [locale, props.courseId]);

	return (
		<Box
			sx={{
				width: "100%",
				pt: {md: "calc(50% + 95px + 5rem)", xs: "calc(50% + 190px + 5rem)"},
				position: "relative",
			}}
		>
			<iframe
				height="100%"
				style={{border: 0, position: "absolute", inset: 0, width: "100%"}}
				ref={frame}
			/>
		</Box>
	);
}

export default OverallProgressWidget;
