import {Localized, useLocalization} from "@fluent/react";
import {Checkbox, Grid2 as Grid, TableRow} from "@mui/material";
import React, {useCallback, useState} from "react";

import CourseTypeSelector from "../courses/CourseTypeSelector";
import parseDate from "../../helpers/parseDate";
import useDateFormat from "../../i18n/useDateFormat";
import CourseType from "../../store/courses/CourseType";
import UserCourse from "../../store/models/UserCourse";
import UserRole from "../../store/models/UserRole";
import {courseService} from "../../store/services/courseService";
import enrolmentService from "../../store/services/enrolmentService";
import EnrolInCourseDialog from "../users/EnrolInCourseDialog";
import SearchAndSelectionDialog from "../../utils/SearchAndSelectionDialog";
import SearchToolbar from "../../utils/SearchToolbar";
import TableCell from "../../utils/tables/Cell";
import type {ColumnDefs} from "../../utils/tables/Head";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";

function useEnrolInAnotherCourseOfTeacherAction(
	organisationName: string,
	originalCourseId: number,
	teacherId: number,
	mobileMode: boolean,
	onDone?: (courseId: number) => void
): [(studentIds: number[]) => void, JSX.Element] {
	const [studentIds, setStudentIds] = useState<number[]>([]);

	const fetchStudents = useCallback(async () => {
		if (studentIds.length === 0) {
			return [];
		}

		const res = await enrolmentService.searchCourseStudents(
			originalCourseId,
			{ids: studentIds},
			{field: "lastName"},
			studentIds.length
		);

		return res.content;
	}, [originalCourseId, studentIds]);

	const coursesSelector = useCallback(
		(props: {onSelected: (ids: number[]) => void; onCancel: () => void}) => (
			<OtherCourseOfTeacherSelectorDialog
				organisationName={organisationName}
				courseId={originalCourseId}
				teacherId={teacherId}
				actionLabel={
					<Localized id="student-management-students-enrol-in-course-dialog-action-enrol">
						Enrol
					</Localized>
				}
				onSelected={props.onSelected}
				onCancel={props.onCancel}
			/>
		),
		[organisationName, originalCourseId, teacherId]
	);

	return [
		setStudentIds,
		<EnrolInCourseDialog
			key="enrol-in-course"
			open={studentIds.length > 0}
			mobileMode={mobileMode}
			CoursesSelector={coursesSelector}
			fetchUsers={fetchStudents}
			onCancel={() => {
				setStudentIds([]);
			}}
			onDone={(courseId) => {
				setStudentIds([]);
				onDone?.(courseId);
			}}
		/>,
	];
}

const columnKeys = ["name", "startDate", "type"] as const;

const columnDefs: ColumnDefs<typeof columnKeys[number], keyof UserCourse> = {
	name: {
		name: (
			<Localized id="teacher-courses-selector-dialog-table-column-name">
				Name
			</Localized>
		),
		sortOptions: [{field: "name"}],
	},
	startDate: {
		name: (
			<Localized id="teacher-courses-selector-dialog-table-column-start-date">
				Start date
			</Localized>
		),
		width: 160,
		align: "right",
	},
	type: {
		name: (
			<Localized id="teacher-courses-selector-dialog-table-column-type">
				Type
			</Localized>
		),
		width: 100,
	},
};

const columns = columnKeys.map((key) => columnDefs[key]);

const columnNumber = columns.length + 1;

const initialCriteria: {query?: string; type?: CourseType} = {};

function OtherCourseOfTeacherSelectorDialog(props: {
	organisationName: string;
	teacherId: number;
	courseId: number;
	actionLabel: NonNullable<React.ReactNode>;
	onSelected: (ids: number[]) => void;
	onCancel: () => void;
}) {
	const [criteria, setCriteria] = useState(initialCriteria);

	const formatDate = useDateFormat();
	const {l10n} = useLocalization();

	const fetchPage = useCallback(
		async (sort, pageSize) => {
			const res = await courseService.searchUserCourses(
				props.organisationName,
				props.teacherId,
				{
					userRole: [UserRole.Teacher],
					status: ["ongoing", "upcoming"],
					query: criteria.query,
					excluded: [props.courseId],
					type: criteria.type,
				},
				sort,
				pageSize
			);

			return res;
		},
		[
			criteria.query,
			criteria.type,
			props.courseId,
			props.organisationName,
			props.teacherId,
		]
	);

	return (
		<SearchAndSelectionDialog
			toolbar={
				<SearchToolbar
					criteria={criteria}
					searchPlaceholder={l10n.getString(
						"teacher-courses-selector-dialog-search-placeholder"
					)}
					onCriteriaChange={setCriteria}
				>
					<Grid container spacing={4}>
						<Grid size={4}>
							<CourseTypeSelector
								value={criteria.type}
								onChange={(type) => setCriteria((prev) => ({...prev, type}))}
							/>
						</Grid>
					</Grid>
				</SearchToolbar>
			}
			actionLabel={props.actionLabel}
			columns={columns}
			defaultSortField="name"
			fetch={fetchPage}
			rowRenderer={(c, selected, select) => {
				const labelId = `table-checkbox-${c.id}`;

				return (
					<TableRow hover key={c.id} tabIndex={-1} selected={selected}>
						<TableCell padding="checkbox">
							<Checkbox
								checked={selected}
								inputProps={{"aria-labelledby": labelId}}
								onClick={select}
							/>
						</TableCell>
						<TableCell
							def={columnDefs.name}
							component="th"
							id={labelId}
							scope="row"
						>
							{c.name}
						</TableCell>
						<TableCell def={columnDefs.startDate}>
							{formatDate(parseDate(c.startDate))}
						</TableCell>
						<TableCell def={columnDefs.type}>
							<Localized id="teacher-courses-type" vars={{type: c.type}}>
								{c.type}
							</Localized>
						</TableCell>
					</TableRow>
				);
			}}
			noSearchResultsRenderer={() => (
				<NoSearchResultsState
					columnNumber={columnNumber}
					title={
						<Localized id="teacher-courses-selector-dialog-no-courses">
							No courses
						</Localized>
					}
					description={
						criteria === initialCriteria ? (
							<Localized id="teacher-courses-selector-dialog-no-courses-descr">
								You have no other courses in the organisation
							</Localized>
						) : (
							<Localized id="teacher-courses-selector-dialog-no-results-descr">
								No courses were found matching your search criteria. Try to
								adjust filters
							</Localized>
						)
					}
				/>
			)}
			generalErrorRenderer={(onReload) => (
				<LoadingErrorState
					description={
						<Localized id="teacher-courses-selector-dialog-loading-error-descr">
							Something has gone wrong, and we cannot load courses
						</Localized>
					}
					columnNumber={columnNumber}
					onReload={onReload}
				/>
			)}
			onSelected={props.onSelected}
			onCancel={props.onCancel}
		/>
	);
}

export default useEnrolInAnotherCourseOfTeacherAction;
