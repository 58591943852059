import {Localized, useLocalization} from "@fluent/react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
	Checkbox,
	Dialog,
	Grid2 as Grid,
	Link,
	TableRow,
	Typography,
} from "@mui/material";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useCallback, useMemo, useRef, useState} from "react";
import {Link as RouterLink, useHistory} from "react-router-dom";

import createTagSearchCriteria from "./createTagSearchCriteria";
import ExercisePreviewTooltip from "./ExercisePreviewTooltip";
import type {
	ChangeExerciseSearchCriteria,
	ExerciseSearchCriteria,
} from "./ExerciseSearchCriteria";
import ExerciseSearchScope from "./ExerciseSearchScope";
import ExerciseTypeChipSelector from "./ExerciseTypeChipSelector";
import ExerciseTypeLabel from "./ExerciseTypeLabel";
import {Page} from "../../helpers/paginatedSearchHelpers";
import parseDate from "../../helpers/parseDate";
import {getSectionPath} from "../../helpers/pathHelpers";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import useMobileMode from "../../hooks/useMobileMode";
import useStateWithDebounce from "../../hooks/useStateWithDebounce";
import useDateFormat from "../../i18n/useDateFormat";
import useDateTimeFormat from "../../i18n/useDateTimeFormat";
import ExerciseTagsSelector from "../learningMaterial/ExerciseTagsSelector";
import SearchScopeSelector from "./SearchScopeSelector";
import selectAllChapterExerciseIds from "../../store/chapters/selectAllChapterExerciseIds";
import selectCourse from "../../store/courses/selectCourse";
import TagSearchResult from "../../store/exercises/TagSearchResult";
import Feature from "../../store/features/Feature";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import {getChapterContent} from "../../store/chapters/getChapterContent";
import ExerciseType from "../../store/exercises/ExerciseType";
import {addExercisesToSection} from "../../store/sections/addExercisesToSection";
import {getSectionContent} from "../../store/sections/getSectionContent";
import type {ExerciseSearchResult} from "../../store/services/exerciseService";
import exerciseService from "../../store/services/exerciseService";
import {closeDialog} from "../../store/ui/modalDialogSlice";
import useSnackbar from "../../store/ui/useSnackbar";
import {selectUserId} from "../../store/userProfile/selectUserProfile";
import ContentLanguageSelector from "../../utils/ContentLanguageSelector";
import SearchAndActionsToolbar from "../../utils/SearchAndActionsToolbar";
import SearchAndSelectionDialog from "../../utils/SearchAndSelectionDialog";
import type {SearchAndSelectionDialogApi} from "../../utils/SearchAndSelectionDialog";
import TableCell from "../../utils/tables/Cell";
import {ColumnDefs} from "../../utils/tables/Head";
import LightTooltip from "../../utils/LightTooltip";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

function AddExistingExercisesDialog(props: {
	organisationName: string;
	courseId: number;
	sectionId: number;
	chapterId: number;
}) {
	const {chapterId, courseId, organisationName, sectionId} = props;

	const userId = useAppSelector(selectUserId);

	const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog();

	const mobileMode = useMobileMode("md");
	const dispatch = useAppDispatch();
	const history = useHistory();
	const showSnackbar = useSnackbar();
	const {l10n} = useLocalization();

	const selectionApi = useRef<SearchAndSelectionDialogApi>(null);

	async function close() {
		dispatch(closeDialog());
	}

	function tryClose() {
		if (selectionApi.current?.somethingSelected()) {
			openConfirmationDialog({
				title: (
					<Localized id="add-existing-exercises-dialog-confirm-closing-title">
						Are you sure?
					</Localized>
				),
				description: (
					<Localized id="add-existing-exercises-dialog-confirm-closing-desc">
						You have chosen exercises to add. If you leave this page, your
						choice will be lost.
					</Localized>
				),
				confirmBtnText: (
					<Localized id="add-existing-exercises-dialog-confirm-closing-action-leave">
						Leave the page
					</Localized>
				),
				cancelBtnText: (
					<Localized id="add-existing-exercises-dialog-confirm-closing-action-stay">
						Continue adding
					</Localized>
				),
				onConfirm: close,
			});
		} else {
			close();
		}
	}

	async function addToCourse(ids: number[]) {
		try {
			const res = await dispatch(
				addExercisesToSection({
					courseId,
					chapterId,
					sectionId,
					exerciseIds: ids,
				})
			);

			unwrapResult(res);
		} catch {
			showSnackbar("error", l10n.getString("error-general"));
			return;
		}

		await dispatch(getChapterContent({courseId, chapterId}));

		dispatch(
			getSectionContent({
				courseId,
				sectionKey: keyProvider.section(chapterId, sectionId),
			})
		);

		close();

		moveToSection();
	}

	function moveToSection() {
		const sectionPath = getSectionPath(
			history.location.pathname,
			props.chapterId,
			props.sectionId
		);

		history.push(sectionPath);
	}

	const titleId = "add-existing-exercises-dialog";

	return (
		<>
			<Dialog
				open
				onClose={tryClose}
				maxWidth="lg"
				fullWidth
				fullScreen={mobileMode}
				aria-labelledby={titleId}
			>
				<Content
					titleElementId={titleId}
					mobileMode={mobileMode}
					userId={userId}
					organisationName={organisationName}
					courseId={courseId}
					chapterId={chapterId}
					selectionApi={selectionApi}
					onAddToCourse={addToCourse}
					onCancel={tryClose}
				/>
			</Dialog>

			{confirmationDialog}
		</>
	);
}

type ColumnKey = "title" | "type" | "typeIcon" | "authorName" | "created";

const columnDefs: ColumnDefs<ColumnKey, keyof ExerciseSearchResult> = {
	title: {
		name: (
			<Localized id="add-existing-exercises-dialog-column-title">
				Title
			</Localized>
		),
		sortOptions: [{field: "title"}],
	},
	type: {
		name: (
			<Localized id="add-existing-exercises-dialog-column-type">Type</Localized>
		),
		width: 50,
	},
	typeIcon: {
		name: "",
		padding: "checkbox",
		width: "1.5rem",
	},
	authorName: {
		name: (
			<Localized id="add-existing-exercises-dialog-column-author">
				Author
			</Localized>
		),
		sortOptions: [{field: "authorName"}],
		width: 125,
	},
	created: {
		name: (
			<Localized id="add-existing-exercises-dialog-column-created">
				Created
			</Localized>
		),
		align: "right",
		sortOptions: [{field: "created"}],
		width: 125,
	},
};

const desktopColumnKeys: ColumnKey[] = [
	"title",
	"type",
	"authorName",
	"created",
];

const mobileColumnsKeys: ColumnKey[] = [
	"typeIcon",
	"title",
	"authorName",
	"created",
];

const desktopColumns = desktopColumnKeys.map((key) => columnDefs[key]);
const mobileColumns = mobileColumnsKeys.map((key) => columnDefs[key]);

function Content(props: {
	titleElementId: string;
	mobileMode?: boolean;

	userId: number;
	organisationName: string;
	courseId: number;
	chapterId: number;

	selectionApi: React.Ref<SearchAndSelectionDialogApi>;

	onAddToCourse: (ids: number[]) => Promise<void>;
	onCancel: (shouldConfirm?: boolean) => void;
}) {
	const [inProgress, setInProgress] = useState(false);

	const [featureEnabled] = useFeatureEnabled();

	const courseKey = keyProvider.course(props.courseId);
	const chapterKey = keyProvider.chapter(props.chapterId);

	const courseCore = useAppSelector(
		(state) => selectCourse(state, courseKey)?.core ?? ""
	);

	const language = useAppSelector(
		(state) => selectCourse(state, courseKey)?.language ?? "en"
	);

	const availableExerciseTypes =
		useAppSelector(
			(state) => selectCourse(state, courseKey)?.availableExerciseTypes
		) || [];

	const chapterExerciseIds = useAppSelector((state) =>
		selectAllChapterExerciseIds(state, chapterKey)
	);

	const addedExercises = useMemo(() => new Set(chapterExerciseIds), [
		chapterExerciseIds,
	]);

	const initialCriteria = useRef<ExerciseSearchCriteria>({
		scope: ExerciseSearchScope.Course,
		language: language,
	});

	const [criteria, setCriteria] = useState(initialCriteria.current);

	const fetchPage = useCallback(
		(sort, pageSize) =>
			exerciseService.searchExercises(
				{
					core: courseCore,
					language: criteria.language,
					exerciseType: criteria.type ?? undefined,
					query: criteria.query,
					courseId:
						criteria.scope === ExerciseSearchScope.Course
							? props.courseId
							: undefined,
					organisationName:
						criteria.scope === ExerciseSearchScope.Organisation
							? props.organisationName
							: undefined,
					userId:
						criteria.scope === ExerciseSearchScope.Personal
							? props.userId
							: undefined,
					tags: criteria.tags,
				},
				sort,
				pageSize
			),
		[
			courseCore,
			criteria.language,
			criteria.query,
			criteria.scope,
			criteria.tags,
			criteria.type,
			props.courseId,
			props.organisationName,
			props.userId,
		]
	);

	const searchTags = useCallback(
		async (prefix: string, pageSize: number) => {
			const tagCriteria = createTagSearchCriteria(
				criteria.scope,
				props.courseId,
				props.userId,
				props.organisationName
			);

			const page = await exerciseService.searchTags(
				{
					...tagCriteria,
					prefix,
				},
				pageSize
			);

			return page;
		},
		[criteria.scope, props.courseId, props.organisationName, props.userId]
	);

	async function addToCourse(ids: number[]) {
		setInProgress(true);

		await props.onAddToCourse(ids);

		setInProgress(false);
	}

	function selectableExercise(id: number) {
		return !addedExercises.has(id);
	}

	const columns = props.mobileMode ? mobileColumns : desktopColumns;

	const columnNumber = columns.length + 1;

	return (
		<>
			<WindowedDialogTitle
				id={props.titleElementId}
				title={
					<Localized id="add-existing-exercises-dialog-title">
						Add exercises
					</Localized>
				}
				inProgress={inProgress}
				onClose={props.onCancel}
			/>

			<SearchAndSelectionDialog
				apiRef={props.selectionApi}
				multiple
				selectable={selectableExercise}
				toolbar={
					<Toolbar
						criteria={criteria}
						initialCriteria={initialCriteria.current}
						availableExerciseTypes={availableExerciseTypes.filter(
							(type) =>
								type !== ExerciseType.External ||
								featureEnabled(Feature.ExternalExercises)
						)}
						onCriteriaChange={setCriteria}
						searchTags={
							featureEnabled(Feature.ExerciseTags) ? searchTags : undefined
						}
					/>
				}
				actionLabel={
					<Localized id="add-existing-exercises-dialog-action-add">
						Add
					</Localized>
				}
				columns={columns}
				fetch={fetchPage}
				defaultSortField={"title"}
				rowRenderer={(exercise, selected, select) => (
					<ExerciseRow
						key={exercise.id}
						courseId={props.courseId}
						exercise={exercise}
						selected={selected}
						added={!selectableExercise(exercise.id)}
						mobileMode={props.mobileMode}
						select={select}
					/>
				)}
				noSearchResultsRenderer={() => (
					<NoSearchResultsState
						columnNumber={columnNumber}
						title={
							<Localized id="add-existing-exercises-dialog-no-results">
								No exercises
							</Localized>
						}
						description={
							criteria === initialCriteria.current ? (
								<Localized id="add-existing-exercises-dialog-no-exercises-descr">
									There are no available exercises
								</Localized>
							) : (
								<Localized id="add-existing-exercises-dialog-no-results-descr">
									No exercises were found matching your search criteria. Try to
									adjust filters
								</Localized>
							)
						}
					/>
				)}
				generalErrorRenderer={(onReload) => (
					<LoadingErrorState
						description={
							<Localized id="add-existing-exercises-dialog-error-descr">
								Something has gone wrong, and we cannot load exercises
							</Localized>
						}
						columnNumber={columnNumber}
						onReload={onReload}
					/>
				)}
				onSelected={addToCourse}
				onCancel={props.onCancel}
			/>
		</>
	);
}

function Toolbar(props: {
	criteria: ExerciseSearchCriteria;
	availableExerciseTypes: ExerciseType[];
	initialCriteria: ExerciseSearchCriteria;
	onCriteriaChange: ChangeExerciseSearchCriteria;
	searchTags?: (
		prefix: string,
		pageSize: number
	) => Promise<Page<TagSearchResult>>;
}) {
	const [criteria, setCriteria] = useStateWithDebounce(
		props.criteria,
		props.onCriteriaChange
	);

	const {l10n} = useLocalization();

	const canClear =
		Boolean(criteria.query) ||
		criteria.language !== props.initialCriteria.language ||
		criteria.scope !== props.initialCriteria.scope ||
		Boolean(criteria.type) ||
		(criteria.tags && criteria.tags.length > 0) ||
		Boolean(criteria.query);

	return (
		<SearchAndActionsToolbar
			value={criteria.query ?? ""}
			placeholder={l10n.getString(
				"add-existing-exercises-dialog-search-placeholder"
			)}
			onChange={(val) => setCriteria((prev) => ({...prev, query: val}))}
			onClear={
				canClear
					? () =>
							setCriteria({
								query: "",
								language: props.initialCriteria.language,
								scope: props.initialCriteria.scope,
								tags: undefined,
								type: undefined,
							})
					: undefined
			}
			renderFilters={() => (
				<CriteriaPanel
					criteria={criteria}
					availableExerciseTypes={props.availableExerciseTypes}
					onCriteriaChange={setCriteria}
					searchTags={props.searchTags}
				/>
			)}
		/>
	);
}

function ExerciseRow(props: {
	courseId: number;
	exercise: ExerciseSearchResult;
	selected: boolean;
	added: boolean;
	mobileMode?: boolean;
	select: () => void;
}) {
	const {exercise, mobileMode} = props;

	const formatDateTime = useDateTimeFormat();
	const formatDate = useDateFormat();

	const {l10n} = useLocalization();

	const labelId = `table-checkbox-${exercise.id}`;

	const created = parseDate(exercise.created);
	const createdDateTime = formatDateTime(created);

	return (
		<TableRow hover tabIndex={-1} selected={props.selected}>
			<TableCell padding="checkbox">
				<Checkbox
					checked={props.selected || props.added}
					disabled={props.added}
					inputProps={{"aria-labelledby": labelId}}
					onClick={props.select}
				/>
			</TableCell>

			{mobileMode && (
				<TableCell def={columnDefs.typeIcon} sx={{whiteSpace: "nowrap"}}>
					<ExerciseTypeLabel type={exercise.type} size="small" />
				</TableCell>
			)}

			<TableCell def={columnDefs.title} component="th" id={labelId} scope="row">
				<ExercisePreviewTooltip exercise={exercise}>
					<Link
						component={RouterLink}
						to={`/courses/${props.courseId}/management/exercises/${exercise.id}`}
						target="_blank"
						rel="noreferrer"
					>
						{exercise.title}
						<OpenInNewIcon
							aria-label={l10n.getString(
								"add-existing-exercises-dialog-hint-opens-in-new-window"
							)}
							sx={{
								color: (theme) => theme.palette.text.secondary,
								verticalAlign: "sub",
								ml: 0.5,
							}}
							fontSize="inherit"
						/>
					</Link>
				</ExercisePreviewTooltip>
			</TableCell>

			{!mobileMode && (
				<TableCell def={columnDefs.type} sx={{whiteSpace: "nowrap"}}>
					<ExerciseTypeLabel type={exercise.type} />
				</TableCell>
			)}

			<TableCell def={columnDefs.authorName}>{exercise.authorName}</TableCell>

			<TableCell def={columnDefs.created} sx={{whiteSpace: "nowrap"}}>
				{mobileMode ? (
					<LightTooltip title={createdDateTime}>
						<Typography variant="inherit" component="span">
							{formatDate(created)}
						</Typography>
					</LightTooltip>
				) : (
					createdDateTime
				)}
			</TableCell>
		</TableRow>
	);
}

function CriteriaPanel(props: {
	criteria: ExerciseSearchCriteria;
	availableExerciseTypes: ExerciseType[];
	searchTags?: (
		prefix: string,
		pageSize: number
	) => Promise<Page<TagSearchResult>>;
	onCriteriaChange: ChangeExerciseSearchCriteria;
}) {
	const {criteria, onCriteriaChange} = props;

	return (
		<Grid container spacing={3}>
			<Grid size={12} container>
				<Grid size={{xs: 12, sm: 6, md: 4, xl: 3}}>
					<SearchScopeSelector
						value={criteria.scope}
						onChange={(val) =>
							onCriteriaChange((prev) => ({...prev, scope: val}))
						}
					/>
				</Grid>
				<Grid size={{xs: 12, sm: 6, md: 4, xl: 3}}>
					<ContentLanguageSelector
						value={criteria.language}
						onChange={(val) =>
							onCriteriaChange((prev) => ({...prev, language: val}))
						}
					/>
				</Grid>
			</Grid>
			<Grid size={12} container spacing={1}>
				<ExerciseTypeChipSelector
					availableExerciseTypes={props.availableExerciseTypes}
					label={
						<Localized id="add-existing-exercises-dialog-type-selector-label">
							Type
						</Localized>
					}
					value={criteria.type}
					onChange={(v) =>
						onCriteriaChange((prev) => ({
							...prev,
							type: prev.type !== v ? v : undefined,
						}))
					}
				/>
			</Grid>
			{props.searchTags && (
				<Grid size={12}>
					<ExerciseTagsSelector
						value={criteria.tags ?? []}
						onChange={(tags) => {
							onCriteriaChange((prev) => ({...prev, tags}));
						}}
						searchTags={props.searchTags}
					/>
				</Grid>
			)}
		</Grid>
	);
}

export default AddExistingExercisesDialog;
