import {Localized, useLocalization} from "@fluent/react";
import ListAlt from "@mui/icons-material/ListAlt";
import {Box, Dialog, Stack, useMediaQuery, useTheme} from "@mui/material";
import {unwrapResult} from "@reduxjs/toolkit";
import React, {useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";

import type {WidgetDefinitions} from "../analytics/widget";
import AddWidgetDialog from "../analytics/widget/AddWidgetDialog";
import type {DataFetcher} from "../analytics/widget/dataFetch";
import {createDataFetcher} from "../analytics/widget/dataFetch";
import {notSupportedWidgetDef} from "../analytics/widget/NotSupportedWidget";
import NoWidgetsEmptyState from "../analytics/widget/NoWidgetsEmptyState";
import useWidgetDataFetch from "../analytics/widget/useWidgetDataFetch";
import WidgetNavigation from "../analytics/widget/WidgetNavigation";
import {getChapters} from "../../store/chapters/getChapters";
import selectCourseChaptersFetchStatus from "../../store/chapters/selectCourseChaptersFetchStatus";
import fetchCourseContentStatistics from "../../store/courseStatistics/fetchCourseContentStatistics";
import {fetchCourseStats} from "../../store/courseStatistics/fetchCourseStats";
import selectCourseContentStatisticsFetchStatus from "../../store/courseStatistics/selectCourseContentStatisticsFetchStatus";
import selectCourseStatsFetchStatus from "../../store/courseStatistics/selectCourseStatsFetchStatus";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import useSnackbar from "../../store/ui/useSnackbar";
import fetchAvailableWidgets from "../../store/widgets/fetchAvailableWidgets";
import fetchWidgets from "../../store/widgets/fetchWidgets";
import insertWidget from "../../store/widgets/insertWidget";
import removeWidget from "../../store/widgets/removeWidget";
import selectAvailableWidgets, {
	selectAvailableWidgetsFetchStatus,
} from "../../store/widgets/selectAvailableWidgets";
import selectWidgetEditing from "../../store/widgets/selectWidgetEditing";
import selectWidgetKeys, {
	selectWidgetFetchStatus,
} from "../../store/widgets/selectWidgetKeys";
import StudentActivityWidget from "./StudentActivityWidget";
import StudentResultsWidget from "./StudentResultsWidget";
import ExtendableBox from "../../utils/ExtendableBox";
import OpenNavigationButton from "../../utils/OpenNavigationButton";
import ScrollToTopOnMount from "../../utils/ScrollToTopOnMount";

const widgetsNavigationDrawerWidth = 360;

type DataKeys = "course_stats" | "chapters" | "course_content_stats";

const widgetDefinitions: WidgetDefinitions<
	{courseId: number; userId: number},
	DataKeys
> = {
	student_results: {
		title: (
			<Localized id="follow-up-student-results-widget-title">
				Student results
			</Localized>
		),
		icon: <ListAlt color="primary" />,
		description: (
			<Localized id="follow-up-student-activity-widget-descr">
				Follow student activity in the course
			</Localized>
		),
		component: StudentResultsWidget,
		requiredData: ["course_stats", "chapters", "course_content_stats"],
	},
	student_activity: {
		title: (
			<Localized id="follow-up-student-activity-widget-title">
				Student activity
			</Localized>
		),
		icon: <ListAlt color="primary" />,
		description: (
			<Localized id="follow-up-student-results-widget-descr">
				Search student results in the course
			</Localized>
		),
		component: StudentActivityWidget,
		requiredData: [],
	},
};

const FollowUp = (props: {
	courseId: number;
	userId: number;
	viewOnly?: boolean;
}): JSX.Element => {
	const {courseId, userId} = props;

	const theme = useTheme();

	const courseKey = keyProvider.course(courseId);

	const widgetKeys = useAppSelector((state) =>
		selectWidgetKeys(state, "follow-up", courseKey)
	);
	const widgetFetchStatus = useAppSelector((state) =>
		selectWidgetFetchStatus(state, "follow-up", courseKey)
	);
	const widgetEditRunning = useAppSelector(selectWidgetEditing);

	const availableWidgets = useAppSelector((state) =>
		selectAvailableWidgets(state, "follow-up", courseKey)
	);
	const availableWidgetsFetchStatus = useAppSelector((state) =>
		selectAvailableWidgetsFetchStatus(state, "follow-up", courseKey)
	);

	const dispatch = useAppDispatch();

	const widgetsShouldBeFetched = widgetFetchStatus === "none";

	useEffect(() => {
		if (widgetsShouldBeFetched) {
			dispatch(fetchWidgets({courseId, dashboardName: "follow-up", userId}));
		}
	}, [courseId, dispatch, userId, widgetsShouldBeFetched]);

	const [navigationOpened, setNavigationOpened] = useState(false);

	const [addWidgetDialogOpen, setAddWidgetDialogOpen] = useState(false);

	const emptyDashboard =
		widgetFetchStatus === "succeeded" && widgetKeys.length === 0;

	const availableWidgetsShouldBeFetched =
		availableWidgetsFetchStatus === "none" &&
		(navigationOpened || emptyDashboard);

	useEffect(() => {
		if (availableWidgetsShouldBeFetched) {
			dispatch(fetchAvailableWidgets({courseId, dashboardName: "follow-up"}));
		}
	}, [availableWidgetsShouldBeFetched, courseId, dispatch]);

	const fetcher = useMemo<DataFetcher<DataKeys>>(() => {
		const courseKey = keyProvider.course(courseId);

		return createDataFetcher(
			dispatch,
			{
				chapters: (state) => selectCourseChaptersFetchStatus(state, courseKey),
				course_stats: (state) => selectCourseStatsFetchStatus(state, courseKey),
				course_content_stats: (state) =>
					selectCourseContentStatisticsFetchStatus(state, courseKey),
			},
			{
				chapters: () => getChapters(courseId),
				course_stats: () => fetchCourseStats({courseId}),
				course_content_stats: () => fetchCourseContentStatistics({courseId}),
			}
		);
	}, [courseId, dispatch]);

	const supportedWidgetKeys = useMemo(() => {
		return widgetKeys.filter((k) => k in widgetDefinitions);
	}, [widgetKeys]);

	const statuses = useWidgetDataFetch(
		fetcher,
		widgetDefinitions,
		["course_stats", "course_content_stats"],
		supportedWidgetKeys
	);

	const mobileMode = useMediaQuery(theme.breakpoints.down("md"));

	const history = useHistory();

	const showSnackbar = useSnackbar();

	const {l10n} = useLocalization();
	const errorMsg = l10n.getString("error-general");

	function toggleNavigation() {
		setNavigationOpened((prev) => !prev);
	}

	function closeDialog() {
		setAddWidgetDialogOpen(false);
	}

	async function addWidget(widgetName: string) {
		closeDialog();

		try {
			const res = await dispatch(
				insertWidget({
					courseId,
					dashboardName: "follow-up",
					userId,
					widgetName,
				})
			);

			unwrapResult(res);

			if (!mobileMode) {
				history.push(`#${name}`);
			}
		} catch {
			showSnackbar("error", errorMsg);
		}
	}

	async function deleteWidget(widgetKey: string) {
		try {
			const res = await dispatch(
				removeWidget({
					courseId,
					dashboardName: "follow-up",
					userId,
					widgetKey,
				})
			);

			unwrapResult(res);

			if (history.location.hash === `#${widgetKey}`) {
				history.push(history.location.pathname + history.location.search);
			}
		} catch {
			showSnackbar("error", errorMsg);
		}
	}

	async function reorderWidgets(widgetName: string, before: string) {
		try {
			const res = await dispatch(
				insertWidget({
					courseId,
					dashboardName: "follow-up",
					userId,
					widgetName,
					place: {before},
				})
			);

			unwrapResult(res);
		} catch {
			showSnackbar("error", errorMsg);
		}
	}

	function selectWidgetDefinition(key: string) {
		return widgetDefinitions[key] ?? notSupportedWidgetDef;
	}

	const notAddedWidgets = availableWidgets
		.filter((w) => !widgetKeys.includes(w.name) && w.name in widgetDefinitions)
		.map((w) => {
			const d = selectWidgetDefinition(w.name);

			return {
				key: w.name,
				title: d.title,
				icon: d.icon,
				description: d.description,
			};
		});

	return (
		<Box display="flex" maxWidth={theme.breakpoints.values.xl}>
			<ScrollToTopOnMount />

			<WidgetNavigation
				open={navigationOpened}
				mobileMode={mobileMode}
				header={
					<Localized id="course-management-subtitlenav-label-follow-up">
						Follow-up
					</Localized>
				}
				widgetKeys={widgetKeys}
				operationRunning={widgetEditRunning}
				onReorder={reorderWidgets}
				onDelete={deleteWidget}
				getWidgetTitle={(key) => selectWidgetDefinition(key).title}
				onClose={toggleNavigation}
				onAdd={
					notAddedWidgets.length > 0
						? () => setAddWidgetDialogOpen(true)
						: undefined
				}
			/>

			<ExtendableBox
				leftExtension={
					navigationOpened || mobileMode ? 0 : widgetsNavigationDrawerWidth
				}
				display="flex"
				p={6}
				minWidth={0}
			>
				<OpenNavigationButton
					hidden={navigationOpened && !mobileMode}
					onClick={toggleNavigation}
				/>

				{widgetKeys.length > 0 && (
					<Stack
						spacing={6}
						sx={{
							width: "100%",
							paddingRight: (theme) => theme.spacing(3),
							marginRight: (theme) => theme.spacing(-3),
						}}
					>
						{widgetKeys.map((key) => {
							const w = selectWidgetDefinition(key);
							return (
								<w.component
									key={key}
									id={key}
									title={w.title}
									courseId={courseId}
									userId={userId}
									viewOnly={props.viewOnly}
									fetch={fetcher.fetch}
									fetchStatuses={statuses}
								/>
							);
						})}
					</Stack>
				)}

				{emptyDashboard && (
					<NoWidgetsEmptyState
						onAdd={
							availableWidgetsFetchStatus === "succeeded"
								? () => setAddWidgetDialogOpen(true)
								: undefined
						}
					/>
				)}
			</ExtendableBox>

			<Dialog open={addWidgetDialogOpen} onClose={closeDialog} maxWidth="sm">
				<AddWidgetDialog
					availableWidgets={notAddedWidgets}
					onSelect={addWidget}
					onClose={closeDialog}
				/>
			</Dialog>
		</Box>
	);
};

export default FollowUp;
