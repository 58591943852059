import {Localized} from "@fluent/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
	Box,
	Container,
	IconButton,
	LinearProgress,
	Menu,
	MenuItem,
	Stack,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import type {DropResult} from "react-beautiful-dnd";

import DragIndicator from "../../dragdrop/DragIndicator";
import AddChartIcon from "../../../icons/AddChartIcon";
import AppBarDependentDrawer from "../../../utils/AppBarDependentDrawer";
import WidgetNavigationItem from "./WidgetNavigationItem";

const widgetsNavigationDrawerWidth = 360;

function WidgetNavigation(props: {
	open: boolean;
	mobileMode: boolean;
	header: React.ReactNode;
	widgetKeys: string[];
	bottomFixedPanel?: React.ReactElement;
	operationRunning?: boolean;
	getWidgetTitle: (key: string) => React.ReactNode;
	onClose: () => void;
	onReorder: (key: string, before: string) => void;
	onDelete: (key: string) => void;
	onAdd?: () => void;
}): JSX.Element {
	const theme = useTheme();

	const {widgetKeys, mobileMode, onClose, operationRunning} = props;

	const [menuAnchor, setMenuAnchor] = useState<null | {
		anchor: HTMLElement;
		key: string;
	}>(null);

	function onDragEnd(result: DropResult) {
		if (!result.destination) {
			return;
		}

		const sourceIndex = result.source.index;
		const destIndex = result.destination.index;

		if (destIndex === sourceIndex) {
			return;
		}

		props.onReorder(
			widgetKeys[sourceIndex],
			widgetKeys[destIndex > sourceIndex ? destIndex + 1 : destIndex]
		);
	}

	function deleteWidget() {
		if (!menuAnchor) {
			return;
		}

		setMenuAnchor(null);
		props.onDelete(menuAnchor.key);
	}

	let content;
	if (widgetKeys.length === 0) {
		content = <EmptyState />;
	} else {
		content = (
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="widget-list">
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{widgetKeys.map((key, i) => (
								<DraggableItem
									index={i}
									id={key}
									key={key}
									dragDisabled={operationRunning}
								>
									<Box display="flex" alignItems="center" flexGrow={1}>
										<WidgetNavigationItem
											number={i + 1}
											targetId={key}
											title={props.getWidgetTitle(key)}
											onClick={mobileMode ? onClose : undefined}
										/>
										<Tooltip
											title={
												<Localized id="widget-nav-item-menu-btn-tooltip">
													Actions
												</Localized>
											}
											enterDelay={500}
											enterNextDelay={500}
										>
											<div>
												<IconButton
													color="primary"
													onClick={({currentTarget}) =>
														setMenuAnchor({anchor: currentTarget, key})
													}
													disabled={operationRunning}
												>
													<MoreVertIcon />
												</IconButton>
											</div>
										</Tooltip>
									</Box>
								</DraggableItem>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		);
	}

	return (
		<>
			<AppBarDependentDrawer
				open={props.open}
				variant={mobileMode ? "temporary" : "persistent"}
				width={
					mobileMode
						? `calc(100vw - ${theme.spacing(7)})`
						: widgetsNavigationDrawerWidth
				}
				anchor="left"
				onClose={onClose}
				topFixedPanel={
					<>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								mt: 1,
								mb: 0.5,
								ml: 2,
								mr: 0.5,
							}}
						>
							<Typography variant="h6">{props.header}</Typography>
							<Stack direction="row">
								<Tooltip
									title={
										<Localized id="widget-add-widget-btn-tooltip">
											Add widget
										</Localized>
									}
									enterDelay={500}
									enterNextDelay={500}
								>
									<div>
										<IconButton
											onClick={props.onAdd}
											color="primary"
											disabled={!props.onAdd || operationRunning}
										>
											<AddChartIcon />
										</IconButton>
									</div>
								</Tooltip>
								<IconButton onClick={onClose} color="primary">
									<ChevronLeftIcon />
								</IconButton>
							</Stack>
						</Box>
						<Box visibility={operationRunning ? "visible" : "hidden"}>
							<LinearProgress />
						</Box>
					</>
				}
				bottomFixedPanel={props.bottomFixedPanel}
			>
				<Box display="flex" flexDirection="column" p={0.5} height="100%">
					{content}
				</Box>
			</AppBarDependentDrawer>

			<Menu
				open={Boolean(menuAnchor?.anchor)}
				onClose={() => setMenuAnchor(null)}
				anchorEl={menuAnchor?.anchor}
			>
				<MenuItem onClick={deleteWidget}>
					<Localized id="widget-nav-item-menu-item-delete">Delete</Localized>
				</MenuItem>
			</Menu>
		</>
	);
}

const useStyles = makeStyles(() =>
	createStyles({
		handle: {
			"@media (hover: hover)": {
				visibility: "hidden",
			},
		},
		item: {
			"&:hover $handle": {
				visibility: "visible",
			},
		},
	})
);

function DraggableItem(props: {
	id: string;
	index: number;
	children: React.ReactNode;
	dragDisabled?: boolean;
}) {
	const classes = useStyles();

	return (
		<Draggable
			draggableId={props.id}
			index={props.index}
			key={props.id}
			isDragDisabled={props.dragDisabled}
		>
			{(provided) => (
				<div ref={provided.innerRef} {...provided.draggableProps}>
					<Box display="flex" className={classes.item} alignItems="center">
						<DragIndicator
							dragHandleProps={provided.dragHandleProps}
							className={classes.handle}
						/>
						{props.children}
					</Box>
				</div>
			)}
		</Draggable>
	);
}

function EmptyState(): JSX.Element {
	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
			}}
		>
			<EventBusyIcon style={{height: "50px", width: "50px"}} color="disabled" />
			<Typography variant="h6">
				<Localized id="widget-nav-empty-state">No widgets</Localized>
			</Typography>
		</Container>
	);
}

export default WidgetNavigation;
