import {Localized, useLocalization} from "@fluent/react";
import {Box, Divider} from "@mui/material";
import React, {useMemo} from "react";
import {Draggable} from "react-beautiful-dnd";
import {useLocation} from "react-router-dom";

import type {MenuItem} from "./ChildDraggableComponent";
import ChildDraggableComponent from "./ChildDraggableComponent";
import {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import useRemoveSubsectionAction from "../learningMaterial/useRemoveSubsectionAction";
import {makeSelectChapterExercise} from "../../store/chapterExercises/makeSelectChapterExercise";
import {useAppSelector} from "../../store/hooks";
import type {ShowSnackbar} from "../../store/ui/useSnackbar";

interface Props {
	parentIndex: number;
	subsectionKey: string;
	index: number;
	courseId: number;
	navigationPath: string;
	deletionDisabled: boolean;
	chapterEditing: boolean;
	viewOnly?: boolean;
	openConfirmDialog: OpenConfirmationDialog;
	showSnackbar: ShowSnackbar;
}

export const ChildComponent = (props: Props): JSX.Element => {
	const location = useLocation();
	const {l10n} = useLocalization();

	const {
		parentIndex,
		subsectionKey,
		index,
		courseId,
		deletionDisabled,
		chapterEditing,
	} = props;

	const selectChapterExercise = useMemo(makeSelectChapterExercise, []);

	const subsection = useAppSelector((state) =>
		selectChapterExercise(state, subsectionKey)
	);

	const removeSubsection = useRemoveSubsectionAction(
		courseId,
		props.openConfirmDialog,
		props.showSnackbar,
		l10n
	);

	if (!subsection) {
		return <></>;
	}

	const hashLink = `#subsection-${subsection.id}`;

	const menuItems: MenuItem[] = [
		{
			action: "Delete",
			text: (
				<Localized id="dragdrop-subsection-action-delete">Delete</Localized>
			),
			handleDelete: () => removeSubsection(subsectionKey, subsection),
			disabled: deletionDisabled || chapterEditing,
		},
	];

	return (
		<Box ml={1.5}>
			<Draggable
				key={subsectionKey}
				draggableId={subsectionKey}
				index={index}
				isDragDisabled={chapterEditing || props.viewOnly}
			>
				{(provided) => (
					<div ref={provided.innerRef} {...provided.draggableProps}>
						<ChildDraggableComponent
							provided={provided.dragHandleProps}
							index={`${parentIndex + 1}.${index + 1}`}
							content={subsection.title}
							menuItems={menuItems}
							navigationPath={props.navigationPath + hashLink}
							active={hashLink === location.hash}
							viewOnly={props.viewOnly}
						/>

						<Divider />
					</div>
				)}
			</Draggable>
		</Box>
	);
};
