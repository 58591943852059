import {useLocalization} from "@fluent/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
	IconButton,
	Link,
	Menu,
	MenuItem,
	TableCell,
	TableRow,
	useTheme,
} from "@mui/material";
import React, {useState} from "react";
import {Link as RouterLink, useHistory} from "react-router-dom";

import createCopyAction from "./createCopyAction";
import ExercisePreviewTooltip from "./ExercisePreviewTooltip";
import ExerciseTypeLabel from "./ExerciseTypeLabel";
import parseDate from "../../helpers/parseDate";
import {OpenConfirmationDialog} from "../../hooks/useConfirmationDialog";
import useDateTimeFormat from "../../i18n/useDateTimeFormat";
import ExerciseType from "../../store/exercises/ExerciseType";
import {ExerciseSearchResult} from "../../store/services/exerciseService";
import {ShowSnackbar} from "../../store/ui/useSnackbar";

const ExerciseBankTableRow = (props: {
	row: ExerciseSearchResult;
	courseId: number;
	userId: number;
	viewOnly?: boolean;
	deleteExercise: (id: number, title: string) => void;
	showSnackbar: ShowSnackbar;
	openConfirmationDialog: OpenConfirmationDialog;
}): JSX.Element => {
	const {
		row,
		courseId,
		userId,
		deleteExercise,
		showSnackbar,
		openConfirmationDialog,
	} = props;
	const formatDate = useDateTimeFormat();
	const history = useHistory();
	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

	const menuEdit = () => {
		history.push(`/courses/${courseId}/management/exercises/${row.id}?editing`);
	};

	const closeMenu = () => {
		setMenuAnchor(null);
	};

	const onCopy = (newId: number) => {
		history.push(`/courses/${courseId}/management/exercises/${newId}?editing`);
	};

	const copyExercise = createCopyAction(
		courseId,
		onCopy,
		openConfirmationDialog,
		showSnackbar
	);

	const editingAllowed = row.authorId === userId || row.courseId === courseId;

	const theme = useTheme();
	const {l10n} = useLocalization();

	const menuOptions = [
		{
			option: l10n.getString(
				"exercise-bank-table-row-option-label-edit",
				null,
				"Edit"
			),
			action: menuEdit,
			hidden: !editingAllowed,
		},
		{
			option: l10n.getString(
				"exercise-bank-table-row-option-label-copy",
				null,
				"Copy"
			),
			action: () => copyExercise(row.id, row.title),
			disabled: row.type === ExerciseType.External,
		},
		{
			option: l10n.getString(
				"exercise-bank-table-row-option-label-delete",
				null,
				"Delete"
			),
			action: () => deleteExercise(row.id, row.title),
			hidden: !editingAllowed,
			disabled: row.type === ExerciseType.External,
		},
	];

	return (
		<>
			<TableRow hover>
				<TableCell component="th" scope="row">
					<ExercisePreviewTooltip exercise={{title: row.title, tags: row.tags}}>
						<Link
							component={RouterLink}
							to={`/courses/${courseId}/management/exercises/${row.id}`}
						>
							<span>{row.title}</span>
						</Link>
					</ExercisePreviewTooltip>
				</TableCell>
				<TableCell style={{padding: theme.spacing(1.75, 2)}}>
					<ExerciseTypeLabel type={row.type} />
				</TableCell>
				<TableCell>{row.authorName}</TableCell>
				<TableCell align="right">
					{formatDate(parseDate(row.created))}
				</TableCell>
				<TableCell padding="checkbox">
					{!props.viewOnly && (
						<IconButton
							color="primary"
							onClick={(e) => setMenuAnchor(e.currentTarget)}
						>
							<MoreVertIcon />
						</IconButton>
					)}
				</TableCell>
			</TableRow>
			<Menu
				anchorEl={menuAnchor}
				open={Boolean(menuAnchor)}
				onClose={closeMenu}
			>
				{menuOptions.map((opt) =>
					opt.hidden ? null : (
						<MenuItem
							key={opt.option}
							onClick={() => {
								closeMenu();
								opt.action();
							}}
							disabled={opt.disabled}
						>
							{opt.option}
						</MenuItem>
					)
				)}
			</Menu>
		</>
	);
};

export default ExerciseBankTableRow;
