import ExerciseSearchScope from "./ExerciseSearchScope";
import {TagSearchCriteria} from "../../store/services/exerciseService";

function createTagSearchCriteria(
	scope: ExerciseSearchScope,
	courseId?: number,
	userId?: number,
	organisationName?: string
): TagSearchCriteria {
	switch (scope) {
		case ExerciseSearchScope.Course:
			return {
				scope: "course_exercises",
				courseId: courseId,
			};

		case ExerciseSearchScope.Personal:
			return {
				scope: "user_exercises",
				userId: userId,
			};

		case ExerciseSearchScope.Organisation:
			return {
				scope: "exercises_public_to_organisation",
				organisationName: organisationName,
			};

		case ExerciseSearchScope.Public:
			return {
				scope: "public_exercises",
			};

		default:
			throw new Error("Unexpected exercise search scope");
	}
}

export default createTagSearchCriteria;
