import {createSelector} from "@reduxjs/toolkit";

import type {RootState} from "../store";

const selectAllChapterExerciseIds = createSelector(
	(state: RootState) => state.chapterSections.byKey,
	(state: RootState) => state.sections.byKey,
	(_: RootState, chapterKey: string) => chapterKey,
	(chapterSections, sections, key) => {
		const chs = chapterSections[key]?.sections ?? [];

		return chs.flatMap((s) => sections[s]?.section?.subsectionIds ?? []);
	}
);

export default selectAllChapterExerciseIds;
