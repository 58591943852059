import {Localized} from "@fluent/react";
import DoneIcon from "@mui/icons-material/Done";
import {
	Button,
	Divider,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
	useTheme,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import fetchCourseManagementModules from "../../store/courses/fetchCourseManagementModules";
import fetchCourseStudyModules from "../../store/courses/fetchCourseStudyModules";
import useAvailableManagementModules from "../../store/courses/useAvailableManagementModules";
import useAvailableStudyModules from "../../store/courses/useAvailableStudyModules";
import {useAppDispatch} from "../../store/hooks";
import UserRole from "../../store/models/UserRole";

function RoleListItem(props: {
	role: UserRole;
	onClick: () => void;
	selected: boolean;
}) {
	const {role, onClick, selected} = props;

	return (
		<ListItem disablePadding>
			<ListItemButton onClick={onClick}>
				<ListItemIcon>
					<DoneIcon
						fontSize="small"
						color="secondary"
						visibility={selected ? "inherit" : "hidden"}
					/>
				</ListItemIcon>
				<ListItemText
					primary={
						<Localized id={`nav-role-selector-${role}`}>{role}</Localized>
					}
				/>
			</ListItemButton>
		</ListItem>
	);
}

const NavProfileRoleSelector = (props: {
	userId: number;
	courseId: number;
	onClick: () => void;
}): JSX.Element => {
	const {courseId, userId} = props;

	const history = useHistory();

	const [selectedRole, setSelectedRole] = useState(UserRole.Student);

	const [studyModules, , studyModulesFetchStatus] = useAvailableStudyModules(
		courseId
	);
	const [
		managementModules,
		,
		managementModulesFetchStatus,
	] = useAvailableManagementModules(courseId);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (studyModulesFetchStatus === "none") {
			dispatch(fetchCourseStudyModules({courseId, userId}));
		}

		if (managementModulesFetchStatus === "none") {
			dispatch(fetchCourseManagementModules({courseId, userId}));
		}
	}, [
		courseId,
		dispatch,
		managementModulesFetchStatus,
		studyModulesFetchStatus,
		userId,
	]);

	useEffect(() => {
		let role = UserRole.Student;
		if (history.location.pathname.includes("/management")) {
			role = UserRole.Teacher;
		}
		setSelectedRole(role);
	}, [history.location.pathname]);

	const selectRole = (roleName: UserRole) => {
		if (roleName !== selectedRole) {
			roleName === UserRole.Student
				? history.replace(`/courses/${courseId}`)
				: history.replace(`/courses/${courseId}/management`);
			props.onClick();
		}
	};

	if (
		managementModulesFetchStatus === "failed" ||
		studyModulesFetchStatus === "failed"
	) {
		return (
			<ModuleLoadingError
				onReload={() => {
					if (studyModulesFetchStatus === "failed") {
						dispatch(fetchCourseStudyModules({courseId, userId}));
					}
					if (managementModulesFetchStatus === "failed") {
						dispatch(fetchCourseManagementModules({courseId, userId}));
					}
				}}
			/>
		);
	}

	return managementModules.length > 0 && studyModules.length > 0 ? (
		<>
			<ListItem>
				<ListItemText
					secondary={<Localized id="nav-role-selector-title">Roles</Localized>}
					secondaryTypographyProps={{variant: "caption"}}
				/>
			</ListItem>
			<RoleListItem
				role={UserRole.Teacher}
				onClick={() => selectRole(UserRole.Teacher)}
				selected={selectedRole === UserRole.Teacher}
			/>
			<RoleListItem
				role={UserRole.Student}
				onClick={() => selectRole(UserRole.Student)}
				selected={selectedRole === UserRole.Student}
			/>
		</>
	) : (
		<></>
	);
};

function ModuleLoadingError(props: {onReload: () => void}) {
	const theme = useTheme();

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				marginTop: theme.spacing(0.5),
			}}
		>
			<Divider style={{marginBottom: theme.spacing(1.5)}} />
			<Typography align="center" variant="subtitle2">
				<Localized id="nav-role-selector-role-loading-error">
					{`Failed to load roles in the course`}
				</Localized>
			</Typography>
			<Button
				onClick={props.onReload}
				color="primary"
				size="small"
				style={{marginTop: theme.spacing(0.5)}}
			>
				<Localized id="loading-error-reload-btn">Reload</Localized>
			</Button>
		</div>
	);
}

export default NavProfileRoleSelector;
