import {axiosInstance as client} from "./axiosInstance";

export const sectionService = {
	addExercises: async (
		courseId: number,
		chapterId: number,
		sectionId: number,
		exerciseIds: number[]
	): Promise<void> => {
		const url = `/api/courses/${courseId}/chapters/${chapterId}/sections/${sectionId}/exercises`;

		await client.post(
			url,
			exerciseIds.map((id) => ({id: id}))
		);
	},

	patchSectionSettings: async (
		courseId: number,
		chapterId: number,
		sectionId: number,
		settings: {
			selectionSize?: number;
			maxScorePerExercise?: number;
		}
	): Promise<void> => {
		const url = `/api/courses/${courseId}/chapters/${chapterId}/sections/${sectionId}`;

		await client.patch(url, settings, {
			headers: {
				"Content-Type": "application/merge-patch+json",
			},
		});
	},
};
