import {Localized} from "@fluent/react";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import {
	Dialog,
	DialogContent,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Typography,
} from "@mui/material";
import React from "react";
import {useHistory} from "react-router-dom";

import exerciseTypeDescriptions from "../exerciseBank/exerciseTypeDescriptions";
import {getSectionPath} from "../../helpers/pathHelpers";
import useMobileMode from "../../hooks/useMobileMode";
import selectCourse from "../../store/courses/selectCourse";
import {exerciseCreationStarted} from "../../store/exercises/exericseDraftSlice";
import ExerciseType from "../../store/exercises/ExerciseType";
import Feature from "../../store/features/Feature";
import useFeatureEnabled from "../../store/features/useFeatureEnabled";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {keyProvider} from "../../store/keyProvider";
import {
	ModalDialogName,
	closeDialog,
	openDialog,
} from "../../store/ui/modalDialogSlice";
import WindowedDialogTitle from "../../utils/WindowedDialogTitle";

function AddSubsectionDialog(props: {
	courseId: number;
	sectionId: number;
	chapterId: number;
}) {
	const mobileMode = useMobileMode("sm");

	const dispatch = useAppDispatch();

	function close() {
		dispatch(closeDialog());
	}

	const titleId = "add-subsection-dialog";

	return (
		<Dialog
			open
			maxWidth="sm"
			fullScreen={mobileMode}
			aria-labelledby={titleId}
			onClose={close}
		>
			<Content
				titleElementId={titleId}
				courseId={props.courseId}
				chapterId={props.chapterId}
				sectionId={props.sectionId}
				onClose={close}
			/>
		</Dialog>
	);
}

const orderedExerciseTypes = [
	ExerciseType.Open,
	ExerciseType.Prog,
	ExerciseType.Multi,
	ExerciseType.Math,
	ExerciseType.External,
	ExerciseType.Theory,
];

function Content(props: {
	titleElementId: string;

	courseId: number;
	sectionId: number;
	chapterId: number;

	onClose: () => void;
}) {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const [featureEnabled] = useFeatureEnabled();
	const externalEnabled = featureEnabled(Feature.ExternalExercises);

	const availableExerciseTypes =
		useAppSelector(
			(state) =>
				selectCourse(state, keyProvider.course(props.courseId))
					?.availableExerciseTypes
		) ?? [];

	const exerciseTypes = orderedExerciseTypes.filter(
		(t) =>
			(t !== ExerciseType.External || externalEnabled) &&
			availableExerciseTypes.includes(t)
	);

	function openExerciseBankDialog() {
		dispatch(
			openDialog({
				dialogName: ModalDialogName.ExerciseBank,
				dialogParams: {
					courseId: props.courseId,
					chapterId: props.chapterId,
					sectionId: props.sectionId,
				},
			})
		);
	}

	function moveToSection() {
		const sectionPath = getSectionPath(
			history.location.pathname,
			props.chapterId,
			props.sectionId
		);

		history.push(sectionPath);
	}

	function selectExerciseType(type: ExerciseType) {
		props.onClose();
		moveToSection();
		dispatch(
			exerciseCreationStarted({
				exerciseType: type,
				sectionId: props.sectionId,
			})
		);
	}

	return (
		<>
			<WindowedDialogTitle
				id={props.titleElementId}
				title={
					<Localized id="learning-material-add-subsection-dialog-title">
						Add subsection
					</Localized>
				}
				onClose={props.onClose}
			/>

			<DialogContent sx={{pb: 1, mt: -2}}>
				<List>
					<ListItem disablePadding>
						<ListItemButton onClick={openExerciseBankDialog}>
							<ListItemAvatar>
								<AddToPhotosIcon color="primary" />
							</ListItemAvatar>
							<ListItemText
								primary={
									<Localized id="learning-material-add-subsection-dialog-item-text-from-bank">
										Add from exercise bank
									</Localized>
								}
								secondary={
									<Localized id="learning-material-add-subsection-dialog-item-desc-from-bank">
										Select and add one or more ready-made exercises
									</Localized>
								}
							/>
						</ListItemButton>
					</ListItem>
				</List>

				<Typography variant="subtitle1">
					<Localized id="learning-material-add-subsection-dialog-label-from-scratch">
						Create from scratch
					</Localized>
				</Typography>

				<List>
					{exerciseTypes.map((t) => {
						const type = exerciseTypeDescriptions[t];
						return (
							<ListItem key={t} disablePadding>
								<ListItemButton onClick={() => selectExerciseType(t)}>
									<ListItemAvatar
										sx={{color: (theme) => theme.palette.primary.main}}
									>
										{type.icon}
									</ListItemAvatar>
									<ListItemText
										primary={type.displayName}
										secondary={type.text}
									/>
								</ListItemButton>
							</ListItem>
						);
					})}
				</List>
			</DialogContent>
		</>
	);
}

export default AddSubsectionDialog;
