import {createSlice} from "@reduxjs/toolkit";

import type WidgetSettings from "./WidgetSettings";
import fetchWidgets from "./fetchWidgets";
import updateWidgetSettings from "./updateWidgetSettings";

type State = {
	byKey: {[key: string]: WidgetSettings | undefined};
};

const initialState: State = {byKey: {}};

const courseWidgetSettingsSlice = createSlice({
	name: "courseWidgetSettings",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchWidgets.fulfilled, (state, {payload}) => {
			state.byKey = {...state.byKey, ...payload.widgetSettings};
		});

		builder.addCase(updateWidgetSettings.fulfilled, (state, {payload}) => {
			state.byKey[payload.key] = payload.settings;
		});
	},
});

export default courseWidgetSettingsSlice.reducer;
