import {Localized, useLocalization} from "@fluent/react";
import {Checkbox, Grid2 as Grid, TableRow} from "@mui/material";
import React, {useCallback, useState} from "react";

import CourseTypeSelector from "./CourseTypeSelector";
import useDateFormat from "../../i18n/useDateFormat";
import parseDate from "../../helpers/parseDate";
import {courseService} from "../../store/services/courseService";
import type OrganisationCourseSearchResult from "../../store/services/dtos/OrganisationCourseSearchResult";
import type {OrganisationCourseSearchCriteria} from "../../store/services/courseService";
import ContentLanguageSelector from "../../utils/ContentLanguageSelector";
import SearchAndSelectionDialog from "../../utils/SearchAndSelectionDialog";
import SearchToolbar from "../../utils/SearchToolbar";
import TableCell from "../../utils/tables/Cell";
import type {ColumnDefs} from "../../utils/tables/Head";
import LoadingErrorState from "../../utils/tables/LoadingErrorState";
import NoSearchResultsState from "../../utils/tables/NoSearchResultsState";

const columnKeys = ["name", "startDate", "type"] as const;

const columnDefs: ColumnDefs<
	typeof columnKeys[number],
	keyof OrganisationCourseSearchResult
> = {
	name: {
		name: (
			<Localized id="organisation-courses-selector-dialog-table-column-name">
				Name
			</Localized>
		),
		sortOptions: [{field: "name"}],
	},
	startDate: {
		name: (
			<Localized id="organisation-courses-selector-dialog-table-column-start-date">
				Start date
			</Localized>
		),
		width: 160,
		align: "right",
		sortOptions: [{field: "startDate"}],
	},
	type: {
		name: (
			<Localized id="organisation-courses-selector-dialog-table-column-type">
				Type
			</Localized>
		),
		width: 100,
	},
};

const columns = columnKeys.map((key) => columnDefs[key]);

const columnNumber = columns.length + 1;

const initialCriteria: OrganisationCourseSearchCriteria = {};

function OrganisationCoursesSelectorDialog(props: {
	organisationName: string;
	actionLabel: NonNullable<React.ReactNode>;
	multiple?: boolean;
	onSelected: (ids: number[]) => void;
	onCancel: () => void;
}) {
	const [criteria, setCriteria] = useState(initialCriteria);

	const formatDate = useDateFormat();

	const fetchPage = useCallback(
		(sort, pageSize) =>
			courseService.searchOrganisationCourses(
				props.organisationName,
				criteria,
				sort,
				pageSize
			),
		[criteria, props.organisationName]
	);

	return (
		<SearchAndSelectionDialog
			toolbar={
				<Toolbar
					criteria={criteria}
					organisationName={props.organisationName}
					onCriteriaChange={setCriteria}
				/>
			}
			multiple={props.multiple}
			defaultSortField="name"
			actionLabel={props.actionLabel}
			columns={columns}
			fetch={fetchPage}
			rowRenderer={(c, selected, select) => {
				const labelId = `table-checkbox-${c.id}`;

				return (
					<TableRow hover key={c.id} tabIndex={-1} selected={selected}>
						<TableCell padding="checkbox">
							<Checkbox
								checked={selected}
								inputProps={{"aria-labelledby": labelId}}
								onClick={select}
							/>
						</TableCell>
						<TableCell
							def={columnDefs.name}
							component="th"
							id={labelId}
							scope="row"
						>
							{c.name}
						</TableCell>
						<TableCell def={columnDefs.startDate}>
							{formatDate(parseDate(c.startDate))}
						</TableCell>
						<TableCell def={columnDefs.type}>
							<Localized id="organisation-courses-type" vars={{type: c.type}}>
								{c.type}
							</Localized>
						</TableCell>
					</TableRow>
				);
			}}
			noSearchResultsRenderer={() => (
				<NoSearchResultsState
					columnNumber={columnNumber}
					title={
						<Localized id="organisation-courses-no-courses">
							No courses
						</Localized>
					}
					description={
						criteria === initialCriteria ? (
							<Localized id="organisation-courses-no-courses-descr">
								There are no courses in the organisation
							</Localized>
						) : (
							<Localized id="organisation-courses-no-results-descr">
								No courses were found matching your search criteria. Try to
								adjust filters
							</Localized>
						)
					}
				/>
			)}
			generalErrorRenderer={(onReload) => (
				<LoadingErrorState
					description={
						<Localized id="organisation-courses-error-descr">
							Something has gone wrong, and we cannot load courses
						</Localized>
					}
					columnNumber={columnNumber}
					onReload={onReload}
				/>
			)}
			onSelected={props.onSelected}
			onCancel={props.onCancel}
		/>
	);
}

function Toolbar(props: {
	organisationName: string;
	criteria: OrganisationCourseSearchCriteria;
	onCriteriaChange: (
		value:
			| OrganisationCourseSearchCriteria
			| ((
					prevState: OrganisationCourseSearchCriteria
			  ) => OrganisationCourseSearchCriteria)
	) => void;
}) {
	const {criteria, onCriteriaChange} = props;

	const {l10n} = useLocalization();

	return (
		<>
			<SearchToolbar
				criteria={criteria}
				searchPlaceholder={l10n.getString(
					"organisation-courses-selector-dialog-search-placeholder"
				)}
				onCriteriaChange={onCriteriaChange}
			>
				<Grid container spacing={4}>
					<Grid size={4}>
						<CourseTypeSelector
							value={criteria.type}
							onChange={(type) => onCriteriaChange((prev) => ({...prev, type}))}
						/>
					</Grid>
					<Grid size={4}>
						<ContentLanguageSelector
							value={criteria.language ?? ""}
							withAny
							onChange={(language) =>
								onCriteriaChange((prev) => ({...prev, language}))
							}
						/>
					</Grid>
				</Grid>
			</SearchToolbar>
		</>
	);
}

export default OrganisationCoursesSelectorDialog;
