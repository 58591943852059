import {useLocalization} from "@fluent/react";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import {
	Box,
	Skeleton,
	Step,
	StepConnector,
	StepContent,
	StepLabel,
	Stepper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import {createStyles, makeStyles, withStyles} from "@mui/styles";
import clsx from "clsx";
import React, {useEffect, useState} from "react";

import createSerialIdProvider from "../../helpers/createSerialIdProvider";
import parseDate from "../../helpers/parseDate";
import useDateTimeFormat from "../../i18n/useDateTimeFormat";
import type {CourseStudentSearchResult} from "../../store/services/enrolmentService";
import notificationsService from "../../store/services/notificationsService";
import StudentActivityRow from "./StudentActivityRow";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			background: theme.palette.background.paper,
			height: "100%",
			width: "100%",
		},
		activityTable: {
			paddingBottom: theme.spacing(3),
		},
		row: {
			"& > td": {
				borderBottom: "unset",
			},
		},
	})
);

const QontoConnector = withStyles(() =>
	createStyles({
		line: {
			borderTopWidth: 3,
			borderRadius: 1,
		},
	})
)(StepConnector);

const useQontoStepIconStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: "flex",
			height: 22,
			alignItems: "center",
			marginLeft: theme.spacing(1),
		},
		circle: {
			width: 8,
			height: 8,
			borderRadius: "50%",
			backgroundColor: "currentColor",
		},
		active: {
			color: theme.palette.primary.main,
		},
		warning: {
			color: theme.palette.warning.main,
		},
		inactive: {
			color: theme.palette.action.disabled,
		},
	})
);

type StepIconColor = "active" | "warning" | "inactive";

function QontoStepIcon(props: {color: StepIconColor}) {
	const classes = useQontoStepIconStyles();

	return (
		<div className={clsx(classes.root, classes[props.color])}>
			<div className={classes.circle} />
		</div>
	);
}

type CourseEventItem = {
	id: string;
	label: React.ReactNode;
	content: React.ReactNode;
	color: StepIconColor;
};

const id = createSerialIdProvider();

const StudentActivity = (props: {
	courseId: number;
	student: CourseStudentSearchResult;
	onCollapse: () => void;
}): JSX.Element => {
	const {student, courseId} = props;

	const classes = useStyles();

	const [events, setEvents] = useState<CourseEventItem[]>(() =>
		Array.from({length: 4}, () => ({
			color: "inactive",
			id: id(),
			label: <Skeleton variant="text" width="40%" />,
			content: <Skeleton variant="text" width="20%" />,
		}))
	);

	const formatDate = useDateTimeFormat();

	const {l10n} = useLocalization();

	useEffect(() => {
		if (!student.id) {
			return;
		}

		notificationsService
			.getUserCourseNotifications(student.id, courseId)
			.then((res) => {
				const courseEvents: CourseEventItem[] = res.map((n) => ({
					id: n.id,
					label: l10n.getString(n.name),
					content: formatDate(parseDate(n.timestamp)),
					color: "warning",
				}));

				if (student.studyStartDate) {
					const startDate = student.studyStartDate;
					let i = res.findIndex((n) => n.timestamp < startDate);
					if (i < 0) {
						i = res.length;
					}

					courseEvents.splice(i, 0, {
						id: id(),
						label: l10n.getString(
							"follow-up-student-activity-widget-event-started-label",
							null,
							"Started studying the course"
						),
						content: formatDate(parseDate(startDate)),
						color: "active",
					});
				} else {
					courseEvents.unshift({
						id: id(),
						label: l10n.getString(
							"follow-up-student-activity-widget-event-not-started-label",
							null,
							"Has not yet started studying the course"
						),
						content: "",
						color: "inactive",
					});
				}

				courseEvents.push({
					id: id(),
					label: l10n.getString(
						"follow-up-student-activity-widget-event-enrolment-label",
						null,
						"Enrolment"
					),
					content: formatDate(parseDate(student.enrolmentDate)),
					color: "active",
				});

				setEvents(courseEvents);
			});
	}, [
		courseId,
		formatDate,
		l10n,
		student.enrolmentDate,
		student.id,
		student.studyStartDate,
	]);

	return (
		<Box className={classes.root}>
			<Table stickyHeader className={classes.activityTable}>
				<TableHead>
					<StudentActivityRow
						student={student}
						actionIcon={<FullscreenExitIcon />}
						onAction={props.onCollapse}
					/>
				</TableHead>
				<TableBody>
					<TableRow className={classes.row}>
						<TableCell colSpan={2}>
							<Stepper orientation="vertical" connector={<QontoConnector />}>
								{events.map((e) => (
									<Step active key={e.id}>
										<StepLabel
											StepIconProps={{icon: <QontoStepIcon color={e.color} />}}
										>
											{e.label}
										</StepLabel>
										<StepContent>{e.content}</StepContent>
									</Step>
								))}
							</Stepper>
						</TableCell>
						<TableCell padding="checkbox" />
					</TableRow>
				</TableBody>
			</Table>
		</Box>
	);
};

export default StudentActivity;
